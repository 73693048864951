@import "../../../theme/base/_colors.scss";

.DashboardReports-Body {
  position: relative;
  margin: 0 1.5% 0 2%;
  h3 {
    color: #4fb8b6;
    font-weight: 600;
    font-size: 2.2rem;
    padding-top: 2.05%;
    padding-bottom: 1.9%;
  }
  .DashboardReports-ProfileInfo {
    position: absolute;
    right: 0;
    top: 0;
    width: 30rem;
    background: #f3f8ff 0% 0% no-repeat padding-box;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2% 1.25% 1.25%;
    h3 {
      color: $primary;
      font-weight: 600;
      font-size: 2.2rem;
      padding: 0;
      margin-bottom: 0;
      text-transform: capitalize;
      cursor: pointer;
    }
    .ant-badge-dot {
      width: 1.15rem;
      height: 1.15rem;
    }
    .anticon {
      font-size: 3rem;
      color: #4fb8b6;
      cursor: pointer;
    }
  }
}