.Reports-Container {
  .Reports-bar-charts {
    background: #f3f8ff 0% 0% no-repeat padding-box;
    border-radius: 15px;
    opacity: 1;
    width: 90%;
    padding-bottom: 34rem;
    height: 28rem;
    margin-top: 4%;
    position: relative;
    left: 5%;
    padding-top: 5%;
    padding-left: 2rem;
    padding-right: 2rem;
    height: 40rem;
    h1 {
      margin: 0;
      font-size: 1.5rem;
    }
    span {
      font-size: 1rem;
      margin-bottom: 2.5%;
    }
  }

  .Reports-pie-charts {
    background: #f3f8ff 0% 0% no-repeat padding-box;
    border-radius: 15px;
    opacity: 1;
    width: 90%;
    height: 35rem;
    // display: flex;
    // justify-content: center;
    text-align: center;
    padding-left: 18%;
    margin-top: 4%;
    padding-bottom: 31rem;
    padding-top: 5%;
    height: 40rem;
  }
  .Reports-Reviews-List-container {
    /* width */
    ::-webkit-scrollbar {
      width: 1.16rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #4fb8b6;
      border-radius: 100rem;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #4fb8b6;
      border-radius: 190px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #4fb8b6;
    }
    .Reports-Reviews-List {
      background: #f3f8ff 0% 0% no-repeat padding-box;
      border-radius: 15px;
      opacity: 1;
      width: 90%;
      height: 35rem;
      margin-top: 4%;
      margin-bottom: 4%;
      padding-top: 4%;
      overflow-y: auto;
      height: 40rem;
      .Reviews-Container {
      }
      .ant-list-split .ant-list-item {
        border-bottom: 0px solid #e8e8e8;
      }
      .Review-btn-setup {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 2%;
        button.ant-btn.Review-Active {
          background-color: #4fb8b6;
          border-radius: 60px;
          height: 3.8rem;
          width: 13.5rem;
          color: #fff;
          &:hover {
            border-color: #4fb8b6;
          }
        }
        button.ant-btn.Review-Hide {
          border-color: #4fb8b6;
          border-radius: 60px;
          height: 3.8rem;
          width: 13.5rem;
          color: #4fb8b6;
          margin-left: 1rem;
          &:hover {
            border-color: #4fb8b6;
          }
        }
      }
    }
  }
}
//  jsdff haushshish
