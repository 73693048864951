@import "../../../../theme/base/_colors.scss";

.DashboardSettings-container {
  .DashboardSettings-body {
    position: relative;
    margin: 1.5% 1.5% 0 2%;
    h3.DashboardSettings-Title {
      color: #4fb8b6;
      font-weight: 600;
      font-size: 2.1rem;
      padding-top: 2.05%;
      padding-bottom: 1.9%;
      cursor: pointer;
    }
    .Notifications-Container {
      overflow: hidden;
      .Notifications-body-content {
        margin-right: 4rem;
        .Notifications-Content {
          width: 96%;
          h3 {
            font-weight: 600;
          }
          .Notifications-Description,
          .Notifications-Description2 {
            color: #000;
            opacity: 0.5;
            margin-bottom: 3.2rem;
            font-size: 1em;
          }
          .Notifications-Description2 {
            margin-top: -1.4rem;
          }
          .Notifications-Content-Title {
            font-weight: 500;
            font-size: 1.17em;
            opacity: 0.8;
            margin-bottom: 0;
          }
          .Notifications-Switch {
            position: relative;
            text-align: right;
            bottom: 2.4rem;
            margin-bottom: 0;
            opacity: 1;
            .ant-switch-checked {
              background-color: black;
            }
          }
          .Notifications-divider {
            margin: -0.8rem 0 2.6rem;
            font-size: 2rem;
          }
        }

        .Manage-Notifications {
          margin-top: 4rem;
          .ant-card-bordered {
            border: 2px solid #e8e8e8;
            border-radius: 10px;
          }
          .ant-card-body {
            padding: 3.4rem 2.6rem 0;
          }
          img {
            width: 3rem;
          }
          p {
            font-weight: 600;
            font-size: 1.7rem;
            opacity: 0.6;
            margin-top: 1.2rem;
            margin-bottom: 3.6rem;
          }
          .Manage-Notifications-Content {
            font-weight: 600;
            color: #81837f;
            font-size: 1.2rem;
            // width: 27rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 56.25em) and (min-width: 600px) {
  .Notifications-Container {
    .Notifications-body-content {
      margin: 0 6rem 6rem;
      p,
      h1 {
        display: none;
      }
      .Dashboard-Notify {
        display: inherit;
        font-weight: 500;
        margin-bottom: 4rem;
        font-size: 4.3rem;
        letter-spacing: 0.1rem;
        margin-top: -0.6rem;
        .anticon svg {
          width: 3rem;
          height: 3.6rem;
          color: black;
          margin-right: 22rem;
        }
      }
      .ant-row {
        display: flex;
        flex-direction: column-reverse;
      }
      .Notifications-Content {
        width: 100%;
        h3 {
          font-size: 4rem;
          color: $primary;
          font-weight: 500;
        }
        .Notifications-Description,
        .Notifications-Description2 {
          opacity: 0.55;
          margin-top: 1.6rem;
          margin-bottom: 2rem;
          font-size: 2.4rem;
          line-height: 1.8;
        }
        .Notifications-Description2 {
          margin-top: -1.4rem;
        }
        .Notifications-Content-Title {
          display: inherit;
          font-weight: 600;
          font-size: 2.7rem;
          opacity: 0.95;
          padding-bottom: 2.8rem;
        }
        .Notifications-Switch {
          display: inherit;
          bottom: 6rem;
        }
        .Notifications-divider {
          margin: -0.8rem 0 2rem;
          height: 0.22rem;
        }
      }

      .ant-col-sm-16 {
        align-self: center;
      }
      .Manage-Notifications {
        margin-top: 3rem;
        margin-bottom: 5rem;
        .ant-card-bordered {
          border: none;
          border-radius: 15px;
          box-shadow: 0px 2px 20px #2e2e2e29;
        }
        .ant-card-body {
          padding: 3rem 4rem 2rem;
        }
        img {
          width: 6rem;
        }
        p {
          display: inherit;
          font-size: 3rem;
          opacity: 0.7;
          margin-top: 1rem;
          margin-bottom: 0.6rem;
        }
        .Manage-Notifications-Content {
          font-weight: 500;
          color: #6c6d6a;
          font-size: 2rem;
          margin-bottom: 3.4rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 37.5em) {
  .Notifications-Container {
    .Notifications-body-content {
      margin: 0 2rem 0 3.5rem;
      p,
      h1 {
        display: none;
      }
      .Dashboard-Notify {
        display: inherit;
        font-weight: 500;
        margin-bottom: 4rem;
        font-size: 2.3rem;
        letter-spacing: 0.1rem;
        margin-top: -0.6rem;
        .anticon svg {
          width: 1.5rem;
          height: 2.1rem;
          color: black;
          margin-right: 9rem;
        }
      }
      .ant-row {
        display: flex;
        flex-direction: column-reverse;
      }
      .Notifications-Content {
        width: 98%;
        h3 {
          font-size: 1.85rem;
          color: $primary;
        }
        .Notifications-Description,
        .Notifications-Description2 {
          opacity: 0.55;
          margin-top: 1.6rem;
          margin-bottom: 2rem;
          font-size: 0.755em;
          line-height: 1.8;
        }
        .Notifications-Description2 {
          margin-top: -1.4rem;
        }
        .Notifications-Content-Title {
          display: inherit;
          font-weight: 600;
          font-size: 0.85em;
          opacity: 0.85;
          padding-bottom: 1.3rem;
        }
        .Notifications-Switch {
          display: inherit;
          bottom: 3.3rem;
          .ant-switch {
            min-width: 3.25rem;
            width: 3.25rem;
            height: 1.6rem;
          }
          .ant-switch::after {
            top: 0.05rem;
            width: 1.25rem;
            height: 1.25rem;
          }
        }
        .Notifications-divider {
          margin: -0.8rem 0 2rem;
        }
      }

      .Manage-Notifications {
        margin-top: 0;
        margin-left: 1rem;
        margin-bottom: 3.25rem;
        .ant-card {
          width: 94%;
        }
        .ant-card-bordered {
          border: none;
          border-radius: 15px;
          box-shadow: 0px 2px 20px #2e2e2e29;
        }
        .ant-card-body {
          padding: 3rem 2.6rem 0;
        }
        img {
          width: 2.5rem;
        }
        p {
          display: inherit;
          font-size: 1.48rem;
          opacity: 0.7;
          margin-top: 1rem;
          margin-bottom: 0.6rem;
        }
        .Manage-Notifications-Content {
          font-weight: 500;
          color: #6c6d6a;
          font-size: 1.14rem;
          margin-bottom: 3.4rem;
        }
      }
    }
  }
}
