
$text-color: #4A4A4A;
$primary:#2D80B5;
$secondary: #81837f;
$white: #fff;
$nav-color:#F1F5FA;
$black:black;
$dark:#777777;

$textColor2: #232323;
$textColor3: #a5a5a5;
$textColor4: #2b2b2b;
$textColor5: #2f2c2c;
$textColor6: #aaa;
$textColor7: #8c9094;
$textColor8: #bfbfbf;
$textColor9: #cccccc;

$backgroundColor1: #f4f7fb;

$border: #e8e8e8;
