.view_payment_container {
    padding: 0.5% 1.5%;

    h5 {
        font-size: 1.4rem;
        font-weight: 400;
        color: #333;
        text-transform: capitalize;
    }

    h4 {
        font-size: 1.4rem;
        color: #333;
        font-weight: 500;
        text-transform: capitalize;
    }

    .view_payments_content {
        margin-top: 2rem;

        h3 {
            color: #4fb8b6;
            font-size: 1.5rem;
            font-weight: 500;
            margin-bottom: 2rem;
        }

        .payment_details {
            display: grid;
            grid-template-columns: 50% 50%;

            .details {
                display: grid;
                grid-template-columns: 35% 10% 55%;
                margin-bottom: 0.5rem;
               
                    .email_text{
                        text-transform: lowercase;
                    }
               
            }
        }
    }

    .visit_details {
        padding: 0% 0 1%;
        display: grid;
        grid-template-columns: 18% 4% 78%;
        margin-bottom: 0.5rem;
    }
}