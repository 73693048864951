@import "../../../../theme/base/_colors.scss";

.DashboardSettings-container {
  .ant-spin-spinning {
    position: absolute;
    right: 40%;
    bottom: 44%;
    z-index: 1;
    color: #4fb8b666;
    .ant-spin-dot {
      font-size: 80px;
    }
  }
  .ant-spin.AccountInfoSPIN {
    position: static;
    .ant-spin-dot {
      font-size: 20px;
    }
    .ant-spin-dot-item {
      background-color: #4fb8b6;
    }
  }
  .DashboardSettings-body {
    position: relative;
    margin: 1.5% 1.5% 0 2%;
    h3 {
      color: #4fb8b6;
      font-weight: 500;
      font-size: 1.8rem;
      text-transform: capitalize;
     
      cursor: pointer;
      .anticon-right {
        font-size: 1.5rem;
        font-weight: 500;
      }
      span {
        font-size: 1.8rem;
        padding-left: 1rem;
      }
    }
    .Accountinfo-Container {
     margin: 0% 3%;
      // Accountinfo-Personaldetails starts here
      .Accountinfo-Personaldetails {
        margin: 3% 0%;
        h2 {
          color: #5fbfbd;
          font-weight: 500;
          font-size: 1.6rem;
        }
        .personal_content_block{
          margin-top: 2rem;
          padding: 2.5% 2%;
          display: grid;
          grid-template-columns: 25% 65%;
          column-gap: 5%;
          border: 1px solid #d9d9d9;
          border-radius: 5px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
          .profileImg{
           text-align: center;
          //  margin-top: 2.5rem;
          .ant-upload-list {
            display: none;
          }
          button.ant-btn.uploadImg {
            background: #5fbfbd 0% 0% no-repeat padding-box;
            border-radius: 30px;
            border-color: #5fbfbd;
            font-size: 1.5rem;
            width: 16rem;
            height: 3.3rem;
            margin-top: 2rem;
            //margin-bottom: 11.5%;
            &:hover,
            &:focus {
              border-color: #5fbfbd;
            }
          }
           .ant-avatar{
            width: 12rem !important;
            height: 12rem !important;
           }
           
          }
          .profileimg_content{
           
          }
        }

       
       
       
       
       
        button.ant-btn.DeleteBtn {
          border-radius: 30px;
          color: #5fbfbd;
          border-color: #5fbfbd;
          font-weight: 500;
          height: 3rem;
          width: 9.6rem;
          &:hover,
          &:focus {
            border-color: #5fbfbd;
          }
        }
      }
      // Accountinfo-Personaldetails ends here

      // Accountinfo-Edit-Details starts here
      .ant-col-lg-10 {
        width: 40%;
      }
      .Accountinfo-Edit-Details {
       
       
        .Edit-Personal-Professionalinfo {
          margin-top: 2%;
          .editProfileInfo_content_block{
            h2 {
              color: #333;
              font-size: 1.6rem;
              font-weight: 500;
             
            }
            .editProfile_content{
              padding-top: 2%;
              display: grid;
              grid-template-columns: 30% 30% 30%;
              column-gap: 3%;
              row-gap: 1rem;
              h5{
                font-size: 1.4rem;
                font-weight: 400;
                color: #333;
                text-transform: capitalize;
              }
              .ant-input{
                font-size: 1.4rem;
                font-weight: 400;
                height: 3.5rem;
              }
              .ant-calendar-picker {
                width: 100%;
              }
            }
          }
          .editProfessionalData{
            h2{
              font-size: 1.6rem;
              font-weight: 500;
              color: #333;

            }
            .editProfessional_content{
              margin-top: 2%;
              display: grid;
              grid-template-columns: 30% 30% 30%;
              column-gap: 3%;
              row-gap: 1rem;
              .personal_info{
                h5{
                  font-size: 1.4rem;
                  font-weight: 400;
                  color: #333;
                  text-transform: capitalize;
                }
                .ant-input{
                  font-size: 1.4rem;
                  font-weight: 400;
                  height: 3.5rem;
                }
                .ant-select {
                  width: 100%;
                  .ant-select-selection--single,
                  .ant-select-selection--multiple {
                    min-height: 3.95rem;
                    border: 1px solid #e5e5e5;
                    border-radius: 5px;
                    margin-bottom: 4.5%;
                    padding-bottom: 9px;
                    .ant-select-selection__choice__content{
                      font-size: 1.4rem;
                      font-weight: 400;
                    }
                    .ant-select-selection__rendered {
                      padding-top: 0.4rem;
                      font-size: 1.5rem;
                      font-weight: 500;
                      min-height: 3.7rem;
                    }
                    &:hover,
                    &:focus {
                      border-color: #e5e5e5;
                    }
                  }
                }
                .ant-select.MultiSelect {
                  margin-top: auto;
                }
                .ant-select-selection--multiple > ul > li,
                .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
                  margin-top: 0.6rem;
                }
                .ant-select-selection--multiple .ant-select-selection__choice {
                  color: #4fb8b6;
                  background-color: #4fb8b612;
                  border: 1px solid #4fb8b6;
                  border-radius: 10px;
                }
              }
            }
           }
            
         
          .Accountinfo-SubEdit {
            padding-top: 4.5%;
          }
          .anticon-plus-circle,
          .anticon-cloud-upload {
            font-size: 2.1rem;
            color: #4fb8b6;
            cursor: pointer;
          }
          .anticon-plus-circle {
            position: absolute;
            right: 1.85%;
            top: 0.5rem;
          }
          .anticon-cloud-upload {
            padding-left: 12%;
            padding-top: 0.4rem;
          }
          .Personal-Professionalinfo-Details {
            position: relative;
         
            
           
            // .BirthdayInput {
            //   width: 62%;
            //   padding-bottom: 3rem;
            //   .ant-calendar-picker {
            //     width: 100%;
            //   }
            //   .ant-calendar-picker-input.ant-input {
            //     color: #575757;
            //     font-size: 1.4rem;
            //     font-weight: 400;
            //     border: 1px solid #e5e5e5;
            //     border-radius: 13px;
            //     width: 100%;
            //     height: 3.95rem;
            //     :hover {
            //       border-color: #e5e5e5;
            //     }
            //   }
            //   .ant-calendar-picker-clear,
            //   .ant-calendar-picker-icon {
            //     display: none;
            //   }
            // }
           
            // .ant-input-affix-wrapper .ant-input-suffix {
            //   position: absolute;
            //   right: 3%;
            //   top: 1.9rem;
            // }
            // .ant-upload-list-item {
            //   display: none;
            // }
            // img {
            //   width: 2rem;
            //   height: 2rem;
            //   cursor: pointer;
            // }
            // .Inputs1 {
            //   display: flex;
            //   flex-direction: column;
            //   width: 62%;
            //   align-items: baseline;
            //   textarea.ant-input {
            //     width: 100%;
            //   }
            //   .ant-select {
            //     width: 100%;
            //   }
            // }
            // textarea.ant-input,
            // input.ant-input {
            //   color: #575757;
            //   font-size: 1.5rem;
            //   font-weight: 500;
            //   border: 1px solid #e5e5e5;
            //   border-radius: 13px;
            //   &:hover,
            //   &:focus {
            //     border-color: #e5e5e5;
            //   }
            // }
            // textarea.ant-input {
            //   margin-bottom: 1.45rem;
            //   width: 62%;
            //   font-size: 1.4rem;
            //   font-weight: 400;
            // }
           
            // .Location-Details {
            //   width: 62%;
            //   margin-bottom: 1.6rem;
            //   .ERROR {
            //     margin-bottom: 0;
            //   }
            // }
          }
          .ERROR2 {
            margin-bottom: -0.2rem !important;
            margin-top: 0 !important;
          }
          .ERROR {
            color: #f44336;
            font-size: 1.1rem;
            padding-left: 30%;
            margin-top: -1.4rem;
            margin-bottom: -0.2rem;
          }
        }
      }
      // Accountinfo-Edit-Details ends here

      // Accountinfo-Edit-CareerDetails starts here
      .ant-col-lg-8 {
        width: 35%;
      }
      .Accountinfo-Edit-CareerDetails {
        margin: 3% 0;
        .anticon {
          font-size: 2.1rem;
          color: #4fb8b6;
          cursor: pointer;
        }
        .anticon-plus-circle {
          font-size: 2rem;
          color: #4fb8b6;
          position: absolute;
          right: -10%;
          bottom: 2rem;
        }
        .anticon-cloud-upload {
          padding-left: 28%;
          padding-top: 0.4rem;
        }
        .ant-upload-list-item-name-icon-count-1 {
          padding-right: 14px;
          display: none;
        }
        .anticon-delete {
          display: none;
          &:hover {
            display: none;
          }
        }
        .Education-Accomplishments {
          .Education-Accomplishments-Title {
            display: flex;
            justify-content: space-between;
            padding-bottom: 4.5%;
            h2 {
              color: #333;
              font-size: 1.6rem;
              font-weight: 500;
              
            }
          }
          .Accountinfo-SubEdit {
            padding-top: 6%;
          }
          .Education-Accomplishments-Details {
            display: flex;
            column-gap: 3rem;
            //align-items: flex-start;
            padding-top: 0.5%;
            position: relative;
            h5 {
              color: #333;
              font-size: 1.4rem;
              font-weight: 400;
              width: 30%;
              text-transform: capitalize;
              // margin-bottom: 0;
              // padding-top: 0.5rem;
            }
            h5.NoTextarea {
              padding-top: 0.9rem;
              text-transform: capitalize;
            }
            textarea.ant-input,
            input.ant-input {
              color: #333;
              font-size: 1.4rem;
              font-weight: 400;
              border: 1px solid #e5e5e5;
              border-radius: 5px;
              &:hover,
              &:focus {
                border-color: #e5e5e5;
              }
            }
            textarea.ant-input {
              margin-bottom: 1.7rem;
              width: 100%;
              font-size: 1.4rem;
              font-weight: 400;
              color: #333;
            }
            .InputCustom {
              width: 100%;
              input.ant-input {
                width: 100%;
                height: 3.95rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .InputCustom2 {
              width: 100%;
              input.ant-input {
                height: 3.95rem;
                width: 100%;
                border-color: #4fb8b6;
                box-shadow: 0px 0px 4px #4fb8b6;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .Inputs1 {
              display: flex;
              width: 100%;
              align-items: baseline;
              flex-direction: column;
              input.ant-input {
                margin-bottom: 0.2rem;
              }
              textarea.ant-input {
                width: 100%;
              }
              textarea.ant-input.TextAreaInput {
                border-color: #4fb8b6;
                box-shadow: 0px 0px 4px #4fb8b6;
              }
              .InputCustom {
                width: 100%;
              }
              .Inner-Field-Details {
                width: 100%;
                position: relative;
                .anticon-minus-circle {
                  position: absolute;
                  color: #ff4d4f;
                  font-size: 2.1rem;
                  right: -13%;
                  top: 24%;
                  z-index: 0;
                  cursor: pointer;
                }
                input.ant-input {
                  margin-bottom: 0.4rem;
                }
              }
            }
            .Location-Details {
              width: 100%;
              .google-places-autocomplete__input {
                margin-bottom: 1.45rem;
              }
            }
          }
          .ERROR {
            color: #f44336;
            font-size: 1.1rem;
            padding-left: 32%;
            margin-top: -1.6rem;
            margin-bottom: 0;
          }
        }
        .Education-Accomplishments-BTN {
          text-align: center;
          padding-top: 6%;
          button.ant-btn.UpdateSaveBtn {
            background: #4fb8b6 0% 0% no-repeat padding-box;
            border-color: #4fb8b6;
            border-radius: 25px;
            font-size: 1.4rem;
            font-weight: 500;
            width: 18rem;
            height: 4.5rem;
            &:hover,
            &:focus {
              border-color: #4fb8b6;
            }
          }
        }
      }
      // Accountinfo-Edit-CareerDetails ends here

      .Location-Details {
        position: relative;
        .google-places-autocomplete__input {
          width: 100%;
          height: 3.5rem;
          padding: 4px 23px 4px 11px;
          color: #575757;
          font-size: 1.3rem;
          font-weight: 400;
          line-height: 1.5;
          background-color: $white;
          background-image: none;
          border: 0.30000001192092896px solid #e5e5e5;
          border-radius: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          outline: none;
          &:hover,
          &:focus {
            border-color: #e5e5e5;
          }
        }
        .PinIMG {
          position: absolute;
          right: 2%;
          top: 20%;
          width: 1.5rem;
        }
        .google-places-autocomplete__suggestions-container {
          position: absolute;
          top: 4.5rem;
          background-color: $white;
          border-radius: 15px;
          box-shadow: 0 2px 20px #e5e5e5;
          z-index: 1000;
          .google-places-autocomplete__suggestion {
            padding: 0.4rem 1rem 0.4rem 1.5rem;
            cursor: pointer;
          }
          .google-places-autocomplete__suggestion:nth-child(1) {
            padding-top: 0.6rem;
          }
          .google-places-autocomplete__suggestion:nth-last-child(1) {
            padding-bottom: 0.6rem;
          }
          .google-places-autocomplete__suggestion:hover {
            background-color: $primary;
            color: $white;
            border-radius: 2px;
          }
        }
      }
    }
  }
}

.ERROR1 {
  color: #f44336;
  font-size: 1.1rem;
  padding-left: 2%;
  margin-top: -1.7rem;
  margin-bottom: 0.1rem;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item-selected:hover
  .ant-select-selected-icon {
  color: #4fb8b6 !important;
  opacity: 1 !important;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #4fb8b612 !important;
}
