@import "../../../theme/base/_colors.scss";

.DashboardBookings-Body {
  position: relative;
  margin: 0 3%;
  .scdules_header {
    height: 6rem;
    .DashboardBookings-ProfileInfo {
      position: absolute;
      right: 0;
      top: 0;
      width: 20rem;
      background: #f3f8ff 0% 0% no-repeat padding-box;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2% 1.25% 1.25%;
      h3 {
        color: #4fb8b6;
        font-weight: 500;
        font-size: 1.6rem;
        padding: 0;
        margin-bottom: 0;
        text-transform: capitalize;
        cursor: pointer;
      }
      .ant-badge-dot {
        width: 1.15rem;
        height: 1.15rem;
      }
      .anticon {
        font-size: 3rem;
        color: #4fb8b6;
        cursor: pointer;
      }
    }
    h3 {
      font-size: 1.8rem;
      font-weight: 500;
      color: #4fb8b6;
      padding-top: 3%;
    }
  }

  .DashboardBookings-Filters {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 3% 0;
    .DashboardBookings-Calendar {
      .ant-calendar-picker-input.ant-input {
        height: 4rem;
        border-radius: 12px;
        width: 16rem;
        font-weight: 500;
        font-size: 1.3rem;
        color: #a5a5a5;
        cursor: pointer;
        &:hover {
          border-color: #d9d9d9;
        }
      }
      .ant-calendar-picker-clear {
        display: none;
      }
    }
    button.ant-btn {
      background: $white 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 29px #b4b4b429;
      border: 1px solid #4fb8b6;
      border-radius: 14px;
      font-size: 1.5rem;
      font-weight: 600;
      height: 4rem;
      width: 12rem;
      color: #4fb8b6;
      cursor: pointer;
      &:hover,
      &:focus {
        border-color: #4fb8b6;
      }
    }
    h3 {
      color: #6a6565 !important;
      margin-bottom: 0;
      font-size: 1.5rem;
      font-weight: 400;
      span {
        color: #4fb8b6;
        font-size: 1.7rem;
        font-weight: 500;
        margin-left: 0.5rem;
      }
    }
  }
  h4 {
    font-size: 1.6rem;
    font-weight: 600;
    color: #4fb8b6;
  }

  .DashboardBookings-Body-Slots {
    border-radius: 10px;
    padding-bottom: 7%;
    .DashboardBookings-Content-Date {
      display: flex;
      height: 6.2rem;
      background: #4fb8b6 0% 0% no-repeat padding-box;
      border: 0.30000001192092896px solid #bfbfbf;
      border-radius: 10px 10px 0px 0px;
      .Prevbutton {
        width: 4.4rem;
        height: 4.4rem;
        margin-top: 0.6rem;
        margin-left: 0.75rem;
        align-self: center;
        cursor: pointer;
      }
      .Nextbutton {
        width: 2.95rem;
        height: 2.95rem;
        margin: 0.1rem 1.3rem 0 0.5rem;
        align-self: center;
        cursor: pointer;
      }
      .DashboardBookings-Slot-Dates {
        display: flex;
        overflow: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        .DateTime {
          display: flex;
          padding: 0 1.7rem;
          cursor: pointer;
          h3 {
            color: $white;
            margin-bottom: 0;
            font-size: 3.6rem;
            align-self: center;
            padding-top: 0.4rem;
          }
          .DAY {
            align-self: center;
            p {
              color: $white;
              margin-bottom: 0;
              font-size: 1.35rem;
              font-weight: 500;
              line-height: 1.36;
              padding-left: 0.8rem;
            }
          }
        }
      }
      .ant-spin-spinning {
        position: absolute;
        right: 46%;
        color: #13b9b6;
        .ant-spin-dot {
          font-size: 120px;
        }
      }
    }
    .DashboardBookings-Content-DayTime {
      display: flex;
      background: #4fb8b60f 0% 0% no-repeat padding-box;
      height: 13rem;
      border-bottom: 5px solid #fff;
      img {
        width: 6rem;
        height: 6rem;
        margin: 1rem 0 0 3rem;
        align-self: center;
      }
      h5 {
        color: #4fb8b6;
        opacity: 0.8;
        width: 100%;
        text-align: center;
        align-self: center;
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 0;
      }
      ::-webkit-scrollbar {
        width: 0.4rem;
        border: 1px solid $border;
        border-radius: 4px;
      }
      ::-webkit-scrollbar-thumb {
        background: $textColor8;
        border-radius: 4px;
      }
      .Book-Details-Slots {
        padding: 1.75% 0.75rem 0 8%;
        overflow-y: auto;
        width: 100%;
        button.ant-btn.SlotBTN {
          background: $white 0% 0% no-repeat padding-box;
          border: 0.30000001192092896px solid #4fb8b6;
          border-radius: 5px;
          color: $white;
          background-color: #4fb8b6;
          margin: 0.8rem 6% 0.8rem 0;
          font-size: 1.6rem;
          font-weight: 400;

          height: 4rem;
          //padding: 0.6rem 0.5rem;
          cursor: pointer;
          &:hover,
          &:focus {
            border-color: #4fb8b6;
            background-color: #4fb8b6;
            color: $white;
          }
        }
      }
    }
    .CancelBookingBTN {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      text-align: center;
      background: #4fb8b60f 0% 0% no-repeat padding-box;
      padding: 4% 0 2% 11%;
      button.ant-btn {
        background: #4fb8b6 0% 0% no-repeat padding-box;
        border-radius: 30px;
        border-color: #4fb8b6;
        color: $white;
        width: 18rem;
        font-size: 1.4rem;
        font-weight: 500;
        height: 3.5rem;
        &:hover,
        &:focus {
          border-color: #4fb8b6;
        }
      }
    }
  }
}

.ant-modal.CancelBooking-Modal {
  width: 70rem !important;

  .ant-modal-content {
    border-radius: 20px;
    background-color: #f3f8ff;
    margin-top: 2rem;
  }

  .ant-modal-body {
    position: relative;
    // background-color: red;

    .IconClose {
      position: absolute;
      color: #4fb8b6;
      font-size: 3rem;
      right: 2rem;
      top: 1.5rem;
      z-index: 2000;
      cursor: pointer;

      svg {
        color: currentColor;
      }
    }
    .CancelBooking-Modal-Body {
      margin: 3%;
      h4 {
        color: #4fb8b6;
        margin-bottom: 2rem;
        font-size: 1.8rem;
        font-weight: 500;
        //text-align: center;
      }

      textarea.ant-input {
        background: var(--half-white);
        border-radius: 5px;
        font-size: var(--text-14);
        margin-bottom: 3rem;
        box-shadow: none;

        &:hover,
        &:focus {
          border-color: #4fb8b6;
        }
      }
    }
    .CancelBooking-Btns {
      text-align: center;

      button.ant-btn {
        font-size: var(--text-14);
        border-radius: 12px;
        width: 12rem;
        height: 4.2rem;
        border-color: #4fb8b6;

        &:hover,
        &:focus {
          border-color: #4fb8b6;
        }
      }

      .CancelBTN {
        background: #4fb8b6;
        margin-right: 1.4rem;
      }

      .ExitBTN {
        color: #4fb8b6;
        background: var(--half-white);
      }
    }
  }
}

// DashboardBookings Slots modal starts here
.DashboardBookings-Modal {
  width: 100rem !important;
  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-close {
    top: 20px;
    right: 20px;
    .anticon {
      font-size: 2.5rem;
    }
  }

  @media screen and (max-width: 56.25em) {
    .ant-modal-close {
      top: 18px;
      right: 10px;
      .anticon {
        font-size: 4rem;
      }
    }
  }

  @media screen and (max-width: 37em) {
    .ant-modal-close {
      top: 0;
      right: 0;
      .anticon {
        font-size: 2.8rem;
      }
    }
  }

  .ant-modal-body {
    padding: 3rem 4rem;
    .DashboardBookings-Modal-Body {
      h1 {
        color: #4fb8b6;
        font-size: 1.8rem;
        font-weight: 600;
        padding-bottom: 1.4rem;
      }
      .DashboardBookings-Slot-Details {
        display: flex;
        align-items: center;
        padding-bottom: 2rem;
        img {
          width: 2.1rem;
        }
        .anticon {
          font-size: 2rem;
          color: $secondary;
        }
        h5 {
          color: $secondary;
          font-size: 1.4rem;
          font-weight: 400;
          padding-left: 1.5rem;
          width: 16rem;
          margin-bottom: 0;
        }
        input.ant-input {
          font-weight: 500;
          color: #4fb8b6;
          text-align: center;
          padding: 0;
          border-radius: 6px;
          box-shadow: none;
          &:focus,
          &:hover {
            border-color: #4fb8b6;
          }
        }
        input.ant-input.Choose-Time {
          font-size: 1.6rem;
          font-weight: 500;
          width: 5rem;
          margin-left: 1rem;
        }
        input.ant-input.Session-BreakTime {
          font-size: 1.6rem;
          font-weight: 500;
          width: 5rem;
          margin-left: -1.8rem;
        }
        h4 {
          color: #4fb8b6;
          font-size: 2.2rem;
          height: 2.8rem;
          margin-top: -0.75rem;
          margin-bottom: 0;
          border-bottom: 1px dashed $secondary;
        }
        h5.TO {
          padding-left: 1rem;
          width: unset;
        }
        button.ant-btn.SlotRepeatBtn {
          background: $white 0% 0% no-repeat padding-box;
          border: 0.30000001192092896px solid #4fb8b6;
          border-radius: 25px;
          color: #4fb8b6;
          font-size: 1.3rem;

          width: 13rem;
          height: 3.5rem;
          margin-right: 2rem;
          font-weight: 400;
          letter-spacing: -0.4px;
        }
        button.ant-btn.WeekOffBtn {
          background: $white 0% 0% no-repeat padding-box;
          border: 0.30000001192092896px solid #4fb8b6;
          border-radius: 50%;
          font-weight: 400;
          font-size: 1.3rem;
          color: #4fb8b6;
          margin-right: 1rem;
          width: 3.8rem;
          height: 3.8rem;
        }

        .Dayoffs-Calendar {
          display: grid;
          grid-template-columns: repeat(4, 14rem);
          grid-row-gap: 1.6rem;
          .ant-calendar-picker-input.ant-input,
          p {
            width: 11rem;
            text-align: center;
            height: 2.8rem;
            font-size: 1.35rem;
            border-radius: 30px;
            color: #8c9094;
            border: 1px dashed #4fb8b6;
          }
          .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
            border-color: #4fb8b6;
          }
          .ant-calendar-picker-clear,
          .ant-calendar-picker-icon {
            display: none;
          }
          p {
            position: relative;
            margin-right: 1.6rem;
            padding: 0.35rem 0;
            border-color: #4fb8b6;
            background-color: #4fb8b60f;
            .anticon {
              font-size: 1.15rem;
              position: absolute;
              right: 0.4rem;
              top: 0.7rem;
              cursor: pointer;
            }
          }
        }

        p {
          font-weight: 500;
          letter-spacing: -0.4px;
          color: #4fb8b6;
          font-size: 1.3rem;
          padding-left: 1rem;
          margin-bottom: 0;
        }
      }
      .DayOffs {
        align-items: baseline;
        img {
          align-self: end;
          padding-top: 0.25rem;
        }
      }
      ::-webkit-scrollbar {
        width: 0.4rem;
        border: 1px solid $border;
        border-radius: 4px;
      }
      ::-webkit-scrollbar-thumb {
        background: $textColor8;
        border-radius: 4px;
      }
      .DashboardBookings-Slot-Timings {
        overflow-y: auto;
        height: 24rem;
        .Book-Slots-Content {
          padding-top: 2.3rem;
          display: flex;
          width: 100%;
          img {
            width: 6rem;
            height: 6.4rem;
          }
          .BookSlotIMG {
            width: 6rem;
            height: 6.4rem;
            align-self: center;
          }
          .Book-SlotTimings {
            padding-left: 0.65rem;
            padding-right: 0.75rem;
            align-self: center;
            button.ant-btn.SlotBtn {
              background: $white 0% 0% no-repeat padding-box;
              border: 0.30000001192092896px solid #4fb8b6;
              color: #4fb8b6;
              border-radius: 8px;
              margin: 1.4rem 1rem 1.4rem 1.6rem;
              //font-size: 1.5rem;
              //font-weight: 500;
              width: 13rem;
              height: 3.5rem;
              padding: 0.4rem 0.5rem 0;
              cursor: pointer;
            }
          }
        }
      }
      .DashboardBookings-Slot-Btns {
        display: flex;
        justify-content: center;
        padding-top: 5rem;
        button.ant-btn.SaveBtn {
          border-color: #4fb8b6;
          background-color: #4fb8b6;
          font-weight: 500;
          font-size: 1.4rem;
          width: 18rem;
          height: 4.5rem;
          border-radius: 25px;

          &:hover,
          &:focus {
            border-color: #4fb8b6;
            background-color: #4fb8b6;
          }
        }
        button.ant-btn.CancelBtn {
          border-color: #4fb8b6;
          color: #4fb8b6;
          font-weight: 500;
          font-size: 1.6rem;
          width: 18rem;
          height: 4.5rem;
          border-radius: 24px;
          margin: 0 2rem;
          &:hover,
          &:focus {
            border-color: #4fb8b6;
            color: #4fb8b6;
          }
        }
      }
      .Errors {
        color: #f44336;
        font-size: 1.1rem;
        padding-left: 36%;
        margin-bottom: 0.4rem;
        margin-top: -2rem;
      }
      .Error1 {
        padding-left: 18%;
      }
      .Error2 {
        padding-left: 32%;
      }
    }
  }
}
// DashboardBookings Slots modal ends here

// Cancel Booking modal starts here
.CancelBooking-Modal {
  width: 70rem !important;

  .ant-modal-content {
    border-radius: 20px;
    background-color: #f3f8ff;
    margin-top: 2rem;
  }

  .ant-modal-body {
    position: relative;

    .IconClose {
      position: absolute;
      color: #4fb8b6;
      font-size: 3rem;
      right: 2rem;
      top: 1.5rem;
      z-index: 2000;
      cursor: pointer;

      svg {
        color: currentColor;
      }
    }
    .cancelAppointment_modal {
      margin: 3%;
      h4 {
        color: #4fb8b6;
        margin-bottom: 2rem;
        font-size: 1.8rem;
        font-weight: 500;
        //text-align: center;
      }

      textarea.ant-input {
        background: var(--half-white);
        border-radius: 5px;
        font-size: var(--text-14);
        margin-bottom: 3rem;
        box-shadow: none;

        &:hover,
        &:focus {
          border-color: #4fb8b6;
        }
      }

      .BtnSetup {
        text-align: center;

        button.ant-btn {
          font-size: var(--text-14);
          border-radius: 12px;
          width: 12rem;
          height: 4.2rem;
          border-color: #4fb8b6;

          &:hover,
          &:focus {
            border-color: #4fb8b6;
          }
        }

        .submitBtn {
          background: #4fb8b6;
          margin-right: 1.4rem;
        }

        .CancelBtn {
          color: #4fb8b6;
          background: var(--half-white);
        }
      }
    }
  }
}

// Cancel Booking modal ends here
