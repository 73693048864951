@import "../../../theme/base/_colors.scss";
.Login-Container {
  // padding-top: 1%;
  padding-left: 8%;
  // background-color: red;
  height: 100vh;
  .Login-contain {
    // height: 100%;
    // background-color: #4fb8b6;
  }
  .home-image-container {
    height: 100%;
    .Loginpage-ImgContent,
    .Loginpage-Border,
    .Loginpage-img {
      height: 100%;
    }
    // background-color: green;
    img {
      width: 100%;
      height: 100%;
    }
  }
  overflow: hidden;
  .HeaderlogoContainer {
    margin-bottom: 5%;
    margin-top: 5%;
    // padding-left: 13%;
    img {
      width: 22rem;
    }
  }
  .ant-spin-spinning {
    position: static;
    display: inline-block;
    opacity: 1;
    text-align: center;
    position: absolute;
    top: 38%;
    left: 38%;
    color: #4fb8b680;
    z-index: 1000;
  }
  .Login-modal-body {
    padding-top: 9%;
    h1 {
      font-size: 3rem;
      font-weight: 600;
      color: $textColor2;
      margin: 0;
    }
    .Login-flelds-inputs {
      input.ant-input {
        height: 4.5rem;
        margin: 1rem 0;
        width: 80%;
        box-shadow: none;
        &:hover,
        &:focus {
          border-color: #4fb8b6;
        }
      }
      .ant-input-affix-wrapper .ant-input-suffix {
        right: 22%;
        font-size: 2rem;
        .ant-input-password-icon {
          color: #4fb8b6;
        }
      }
      .ERROR {
        color: #f44336;
        font-size: 1.1rem;
        text-align: left;
        margin-top: -0.6rem;
        margin-bottom: 0.6rem;
      }
      .NO-ERROR {
        margin-top: 0;
        margin-bottom: 1em;
      }
    }
    .Login-agree {
      width: 80%;
      span {
        cursor: pointer;
        font-weight: 500;
      }
      span:nth-child(2) {
        float: right;
      }
    }
    .button-next {
      text-align: center;
      margin-top: 4%;
      margin-right: 20%;
      img {
        width: 5rem;
        margin-bottom: 6rem;
        cursor: pointer;
      }
    }
    .Login-account-check {
      text-align: center;
      margin-right: 25%;
      font-weight: 500;
      .active-color {
        color: #4fb8b6;
        cursor: pointer;
      }
    }
  }
  .Loginpage-ImgContent {
    text-align: center;
    // padding-top: 1%;
    h1 {
      font-size: 3rem;
      color: #fff;
      margin: 7% 0 2% 8%;
      position: absolute;
      /* left: -1rem; */
      text-align: left;
      left: -3rem;
      top: 35%;
    }
    span {
      font-size: 1.8rem;
      margin: 7% 0 2% 8%;
      position: absolute;
      color: #fff;
      text-align: left;
      left: -3rem;
      top: 40%;
      width:45%
    }
    .Loginpage-Border {
      border-left: 2px solid #d9d9d9;
      // padding-top: 10%;
    }
    p {
      color: $secondary;
      font-size: 1.65rem;
      line-height: 2;
      margin-bottom: 0;
    }
    .Loginpage-img {
      // position: relative;
      // bottom: 5%;
      // left: 4%;
      // width: 100%;
      img {
        // width: 100%;
        // height: 40rem;
      }
    }
  }
}

@media only screen and (max-width: 75em) {
  .Login-modal-body {
    margin-left: 2rem;
    .Login-flelds-inputs {
      input.ant-input {
        font-size: 1.3rem;
      }
    }
    .Login-agree {
      span:nth-child(2) {
        font-size: 1.2rem;
        padding-top: 0.3rem;
      }
    }
    .button-next {
      padding-top: 8%;
    }
  }
}