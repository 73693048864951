@import "../../../theme/base/_colors.scss";
.issuesAppoinmentData .ant-tooltip-inner {
  color: $white;
  //background-color: #4fb8b6;
}
.MainDashboard-Body {
  padding-bottom: 2%;
  // MainDashboard-Slot-Timings starts here

  .MainDashboard-Slot-Timings {
    background: #4fb8b6 0% 0% no-repeat padding-box;
    border-radius: 15px;
    margin-top: 3%;
    margin-left: 3.25%;
    padding-bottom: 2%;
    .Slot-Timings-Today {
      display: flex;
      justify-content: space-between;
      padding: 2.25% 2.25% 0 5.25%;
      h3 {
        font-size: 1.6rem;
        font-weight: 500;
        color: $white;
        //margin-bottom: 0.6rem;
      }
      p {
        color: $white;
        font-size: 1.6rem;
        font-weight: 500;
        padding-top: 0.35rem;
        margin-bottom: 0;
      }
    }
    ::-webkit-scrollbar {
      display: none;
    }
    .Slot-Timings-NoContent {
      height: 9.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      h5 {
        color: $white;
        font-size: 1.6rem;
        font-weight: 500;
        //opacity: 0.75;
        //margin-bottom: 0;
      }
    }
    .Slot-Timings-Today-Body {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      .Slot-Timings-Today-Content,
      .Slot-Timings-Today-Content2 {
        display: flex;
        height: 9.2rem;
        margin-right: 1%;
        img {
          width: 5rem;
          height: 5rem;
        }
        .SlotIMG {
          width: 6rem;
          height: 6.4rem;
          margin-left: 2rem;
          align-self: center;
          margin-top: 1.1rem;
        }
        .Slot-Timings {
          align-self: center;
          display: grid;
          grid-template-rows: repeat(2, 50%);
          grid-template-columns: auto;
          grid-auto-flow: column;
          button.ant-btn.SlotBtn {
            background: $white 0% 0% no-repeat padding-box;
            border: 2px solid $white;
            color: #21597f;
            border-radius: 15px;
            // margin-left: 1.6rem;
            margin-right: 1rem;
            margin-top: 0.8rem;
            font-size: 1.2rem;
            font-weight: 500;
            width: 9rem;
            height: 3rem;
            padding: 0.45rem 0.5rem;
            cursor: pointer;
            &:focus,
            &:hover {
              border-color: $white;
              background-color: #4fb8b6;
              color: $white;
            }
          }
        }
      }
      .Slot-Timings-Today-Content2 {
        margin-right: 2rem;
      }
    }
  }
  // MainDashboard-Slot-Timings ends here

  // MainDashboard-Body-Overview starts here
  .MainDashboard-Body-Overview {
    padding-top: 3%;
    padding-left: 2%;
    h3 {
      color: #4fb8b6;
      font-size: 1.8rem;
      font-weight: 500;
      padding-left: 1.25%;
      padding-bottom: 0.8rem;
      margin-bottom: 0;
    }
    p {
      color: $black;
      opacity: 0.4;
      padding-left: 1.25%;
      font-size: 1.35rem;
      font-weight: 600;
      padding-bottom: 0.8rem;
    }
    .MainDashboard-Body-Overview-Content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      .Overview-Details,
      .Overview-Details-1,
      .Overview-Details-2,
      .Overview-Details-3,
      .Overview-Details1-4 {
        // border: 1px solid #d6d6d6;
        border-radius: 15px;
       
        //width: 23%;
        margin: 0 0.8rem;
        .Overview-Details-Title {
         
          //padding-left: 1.7rem;
          h4 {
            color: #333333;
            font-size: 1.4rem;
            font-weight: 500;
            margin-bottom: 1.15rem;
            opacity: 0.95;
          }
          .OverviewDay {
            color: #81837f;
            font-weight: 500;
            font-size: 1.2rem;
            opacity: 0.75;
            padding: 0;
            margin-bottom: 1.1rem;
            align-self: center;
          }
        }
        .Overview-Details-Count {
          display: flex;
          //padding-left: 1.7rem;
          justify-content: space-between;
          align-items: center;
          h1 {
            color: #4fb8b6;
            font-size: 2rem;
            font-weight: 500;
            margin-bottom: 0;
          }
          .OverviewDay-Count {
          
            align-self: center;
           
            
          }
        }
      }
      .Overview-Details,
      .Overview-Details-1,
      .Overview-Details-2,
      .Overview-Details-3,
      .Overview-Details1-4 {
        //width: 24%;
        padding: 1rem;
        background-color: #e9f4fa;
        img {
          width: 4rem;
          margin-bottom: 1rem;
          margin-top: 0.5rem;
        }
      }
      .Overview-Details-1 {
        //width: 23%;
        background-color: #ecf7f7;
      }
      .Overview-Details-2 {
        //width: 23%;
        background-color: #fff3f7;
      }
      .Overview-Details-3 {
        //width: 23%;
        background-color: #fdf9ee;
      }
      .Overview-Details1-4 {
        //width: 23%;
        background-color: #f5f3ff;
      }
    }
  }
  // MainDashboard-Body-Overview ends here

  // MainDashboard-ScheduledAppointment starts here
  .MainDashboard-ScheduledAppointment {
    padding-left: 2%;
    position: relative;
    h3 {
      padding-top: 4%;
      color: #4fb8b6;
      font-size: 1.8rem;
      font-weight: 500;
      padding-left: 1.25%;
      padding-bottom: 0.7rem;
      span {
        color: $primary;
        font-size: 1.75rem;
      }
    }
    .ScheduledAppointment-Content {
      position: relative;
      padding: 6% 0 3.5% 3.5%;
      background: #f3f8ff 0% 0% no-repeat padding-box;
      border-radius: 24px;
      .Appointment-Details,
      .Appointment-Details1 {
        display: grid;
        grid-template-columns: repeat(2, 40%);
        .appoinmentSpan {
          color: $black;
          opacity: 1 !important;
        }
        .DETAILS {
          display: flex;
          h4 {
            color: #333;
            font-size: 1.4rem;
            font-weight: 400;
            padding-bottom: 0.5rem;
            //opacity: 0.57;
            text-transform: capitalize;
            width: 10rem;
          }
          h4:nth-child(3) {
            // width: 20rem;
            font-size: 1.4rem;
            font-weight: 500;
            width: 100%;
            padding-left: 1.5rem;
          }
          .anticon {
            font-size: 1.8rem;
            color: #4fb8b6;
            opacity: 1;
            cursor: pointer;
            vertical-align: sub;
          }
          span {
            color: #333;
            font-size: 1.4rem;
            font-weight: 500;
            padding: 0 0rem;
            text-transform: capitalize;
            //opacity: 0.57;
          }
        }
      }
      .Appointment-Details1 {
        grid-template-columns: repeat(2, 39%);
        h4 {
          width: 10rem !important;
        }
        h4:nth-child(3) {
          // width: 20rem;
          font-size: 1.4rem;
          font-weight: 500;
          width: 100% !important;
        }
      }
    }
    .Appointment-MeetingCard {
      position: absolute;
      background: #313131 0% 0% no-repeat padding-box;
      border: 2px solid #ffffff;
      border-radius: 24px;
      top: 30%;
      height: 71%;
      right: 0;
      img {
        //width: 18rem;
        margin-right: 4%;
        height: 15rem;
      }
      h4 {
        text-align: center;
        font-size: 1.8rem;
        font-weight: 400;
        text-transform: capitalize;
        color: $white;
        span{
          padding-left: 0.5rem;
        }
      }
      h4:nth-child(1) {
       // padding-top: 3rem;
       font-size: 1.4rem;
       font-weight: 400;
        margin-bottom: 0.2rem;
      }
      h4:nth-child(2) {
        margin-bottom: 3.2rem;
        font-size: 1.4rem;
        font-weight: 500;
      }
      .Meetingcard-Btns {
        text-align: center;
        //padding-bottom: 3.4rem;
        button.ant-btn.startBtn {
          width: 10.5rem;
          height: 3.5rem;
          font-size: 1.4rem;
          font-weight: 400;
          background-color: #3685b8;
          border-color: #3685b8;
          border-radius: 24px;
          margin-right: 2rem;
          cursor: pointer;
          &:hover,
          &:focus {
            background-color: #3685b8;
            border-color: #3685b8;
          }
        }
        button.ant-btn.medicationBtn {
          width: 10.5rem;
          height: 3.5rem;
          font-size: 1.4rem;
          font-weight: 400;
          background-color: #4fb8b6;
          border-color: #4fb8b6;
          border-radius: 24px;
          padding-left: 1rem;
          cursor: pointer;
          &:hover,
          &:focus {
            background-color: #4fb8b6;
            border-color: #4fb8b6;
          }
        }
      }
    }
  }
  // MainDashboard-ScheduledAppointment ends here

  // MainDashboard-UpcomingAppointments starts here
  .MainDashboard-UpcomingAppointments {
    padding-left: 2%;
    h3 {
      padding-top: 3.75%;
      color: #4fb8b6;
      font-size: 1.8rem;
      font-weight: 500;
      padding-left: 1.25%;
      padding-bottom: 1rem;
    }
    .UpcomingAppointments-Table {
      .ant-spin-spinning {
        .ant-spin-dot-item {
          background-color: #4fb8b6;
        }
      }
      .ant-table-thead > tr:first-child > th:first-child {
        border-top-left-radius: 8px;
      }
      .ant-table-thead > tr > th {
        background: #4fb8b6 0% 0% no-repeat padding-box;
        color: $white;
        font-size: 1.6rem;
        font-weight: 400;
        padding-bottom: 1.5rem;
      }
      .ant-table-thead > tr:first-child > th:last-child {
        border-top-right-radius: 8px;
      }
      .ant-table-thead > tr > th{
        font-size: 1.4rem;
        font-weight: 500;
        text-align: center;
      }
      .ant-table-placeholder {
        border-top: none;
        border-radius: 20px;
      }
      .ant-table-tbody > tr > td {
        font-size: 1.4rem;
        font-weight: 400;
        color: #333333;
        height: 7.5rem;
        overflow-wrap: anywhere;
        text-transform: capitalize;
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        border-bottom: 1px solid #4fb8b666;
        h4{
          color: #4fb8b6;
          font-size: 1.4rem;
          font-weight: 400;
        }
      }
      .ant-table-tbody > tr:nth-last-child(1) > td {
        border-bottom: none;
      }
      .ant-table-body {
        border-radius: 8px;
        background: #f3f8ff 0% 0% no-repeat padding-box;
      }
      .ant-table-pagination.ant-pagination {
        float: unset !important;
        text-align: center;
        .ant-pagination-item-active {
          background: #4fb8b6;
          border-color: #4fb8b6;
          border-radius: 50%;
        }
        .ant-pagination-item a {
          color: #222222;
          padding-top: 1px;
          padding-bottom: 1px;
          &:hover {
            color: #4fb8b6;
          }
        }
        .ant-pagination-item-active a {
          color: $white;
          &:hover {
            color: $white;
          }
        }
        .ant-pagination-prev {
          margin-right: 1rem !important;
        }
        .ant-pagination-next {
          margin-left: 1rem !important;
        }
        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
          background: $white !important;
          border-radius: 50%;
          box-shadow: 0 0.6px 2px 0.25px;
        }
        .ant-pagination-item:not(.ant-pagination-item-active) {
          border-radius: 50%;
        }
        .anticon {
          vertical-align: -1px;
          color: #bfbfbf;
        }
      }
    }
  }
  // MainDashboard-UpcomingAppointments ends here
}

button.ant-btn.StatusBTN {
  background-color: #4fb8b6;
  border-color: #4fb8b6;
  border-radius: 24px;
  font-size: 1.4rem;
  font-weight: 400;
  &:hover,
  &:focus {
    background-color: #4fb8b6;
    border-color: #4fb8b6;
  }
}

.InfoIcon {
  color: #4fb8b6 !important;
  font-size: 3rem;
  cursor: pointer;
}

// Medication Modal starts here
.Medication-Modal {
  width: 60% !important;
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-body {
    padding-top: 10%;
    padding-right: 8%;
    position: relative;
    .CloseIcon2 {
      position: absolute;
      color: #4fb8b6;
      font-size: 3rem;
      right: 2rem;
      top: 1.5rem;
      cursor: pointer;
    }
    .Send-button-setup {
      text-align: right;
      margin-bottom: 3%;
      button.ant-btn.info-Send {
        width: 9rem;
        height: 3.5rem;
        font-size: 1.4rem;
        font-weight: 500;
        background-color: #4fb8b6;
        border-color: #4fb8b6;
        border-radius: 10px;
        //padding-left: 1rem;
        color: $white;
        cursor: pointer;
      }
    }
    .MedicationModal-Body {
      position: relative;
      .anticon-plus-circle {
        position: absolute;
        color: #4fb8b6;
        font-size: 3rem;
        right: -6%;
        top: 2%;
        z-index: 1000;
        cursor: pointer;
      }
      .MedicationModal-Body-Table {
        .ant-spin-spinning {
          .ant-spin-dot-item {
            background-color: #4fb8b6;
          }
        }
        .ant-table-thead > tr:first-child > th:first-child {
          border-top-left-radius: 14px !important;
          border-bottom-left-radius: 14px !important;
        }
        .ant-table-placeholder {
          position: absolute;
          top: 38%;
          right: 44%;
          border: none;
          background: none;
          border-top: none !important;
        }
        .ant-table-thead > tr > th {
          background: $white;
          color: #4fb8b6;
          font-size: 1.6rem;
          font-weight: 400;
          padding-bottom: 1.5rem;
          overflow-wrap: anywhere;
        }
        .ant-table-thead > tr:first-child > th {
          border: 1px solid #4fb8b6;
          border-right: 0;
          border-radius: 0;
        }
        .ant-table-thead > tr:first-child > th:last-child {
          border-top-right-radius: 14px !important;
          border-bottom-right-radius: 14px !important;
          border-right: 1px solid #4fb8b6;
        }
        .ant-table-thead > tr > th {
          text-align: center;
          padding: 8px;
        }
        .ant-table-tbody > tr > td {
          // padding-left: 4.5%;
        }
        .ant-table-tbody > tr > td {
          font-size: 1.65rem;
          font-weight: 500;
          color: #333333;
          text-transform: capitalize;
          white-space: anywhere;
          text-align: center;
        }
        // .ant-table-tbody > tr > td {
        //   padding-left: 4.5%;
        // }
        // .ant-table-tbody > tr > td {
        //   font-size: 1.65rem;
        //   font-weight: 500;
        //   color: #333333;
        //   text-transform: capitalize;
        // }
        .ant-table-tbody > tr > td:nth-last-child(2) {
          text-transform: lowercase;
        }
        .ant-table-tbody > tr > td {
          border-bottom: 0;
        }
        .ant-table-body {
          height: 25vh;
          border-radius: 5px;
          background: $white 0% 0% no-repeat padding-box;
        }
      }
    }
  }
}
.ant-select.MedicationTable-Select,
.ant-select.MedicationTable-Select2 {
  width: 16.4rem;
  .ant-select-selection--single {
    background: none;
    border: none;
    color: #4fb8b6;
    box-shadow: none;
    &:hover {
      border: none;
    }
  }
  .ant-select-selection__rendered {
    margin-left: 0;
    line-height: 34px;
  }
}
.ant-select.MedicationTable-Select2 {
  width: 10rem;
}
input.ant-input.MedicationTable-Input {
  background: none;
  border: none;
  color: #4fb8b6;
  box-shadow: none;
  &:hover {
    border: none;
  }
}
input.ant-input.Duration {
  width: 10rem;
}
// Medication Modal ends here

// Appointments-InfoModal starts here
.Appointments-InfoModal {
  width: 70% !important;
  .ant-modal-content {
    border-radius: 8px;
    // background-color: #f3f8ff;
    margin-top: 2rem;
    ::-webkit-scrollbar {
      width: 0.8rem;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #4fb8b6;
      border-radius: 10rem;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #4fb8b6;
      border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: #4fb8b6;
    }
    ::-webkit-scrollbar-button {
      width: 50px;
    }
  }
  .ant-modal-body {
    padding: 0;
    position: relative;
    max-height: 94vh;
    overflow: auto;
    .IconClose {
      position: sticky;
      color: #4fb8b6;
      font-size: 3rem;
      float: right;
      right: 1.5rem;
      top: 1.5rem;
      cursor: pointer;
    }

    .AppointmentDetails_Container {
      padding:4% 3% 2% 4%;
     
      h3 {
        font-size: 1.6rem;
        font-weight: 500;
        color: #4fb8b6;
        padding: 1% 0;
      }
      .view_booking_info1{
       
        h5{
          font-size: 1.4rem;
          font-weight: 500;
          color: #333;
          opacity: 0.8;
        }
        h4 {
          font-size: 1.4rem;
          color: #333;
          font-weight: 500;
          margin-bottom: 0;
          text-transform: capitalize;
      }
      }

      .viewAppointment_details {
      
        .view_booking_content {
          width: 100%;
          display: grid;
          grid-template-columns: 48% 48%;
          grid-column-gap: 4%;
          //padding: 0 2%;
    
          .view_booking_info {
            display: grid;
            grid-template-columns: 35% 5% 60%;
            width: 100%;
            margin-bottom: 1.2rem;
            overflow-wrap: anywhere;
    
            h5 {
              font-size: 1.4rem;
              opacity: 0.8;
              font-weight: 500;
              margin-bottom: 0;
              text-transform: capitalize;
            }
    
            span {
              margin-right: 5%;
            }
    
            h4 {
              font-size: 1.4rem;
              color: #333;
    
              font-weight: 500;
              margin-bottom: 0;
              text-transform: capitalize;
              span{
                padding-left: 1%;
              }
            }
            a{
              font-size: 2.6rem;
              margin-left: 15px;
              color: #2d80b5;
            }
            .email_text{
              text-transform: lowercase;
            }
          }
        }
      }
      .Basic_Questions {
        h4 {
          font-size: 1.4rem;
          color: #333;
          opacity: 0.8;
          font-weight: 500;
        }

        .Questions-Content,
        .Questions-Content1 {
          display: flex;
          align-items: baseline;
          // grid-template-columns: repeat(4, 20%);
          flex-wrap: wrap;
          align-items: baseline;
          padding-top: 0.2rem;
          padding-bottom: 2.5%;

          .anticon-up-cirlce {
            font-size: 0.5rem;
            color: #2d80b5;
          }

          h5 {
            padding-left: 0.8rem;
            font-size: 1.4rem;
            font-weight: 500;
            color: #333;
          }

          .anticon svg {
            color: #67696c;
            background-color: #67696c;
            border-radius: 50%;
            font-size: 1.1rem;
          }
          font-size: 1.1rem;

          h3 {
            padding-left: 1.3%;
            margin-right: 9.5%;
            font-size: 1.4rem;
          }
        }

        .questions-Content-data {
          display: grid;
          width: 100%;
          grid-template-columns: 20% 20% 20% 20%;

          h5 {
            span {
              margin-left: 1rem;
            }
          }
        }
      }
    
      // overflow-y: auto;
    
    
    
    
      .Details-Content {
        display: flex;
        padding-bottom: 3.5%;
    
        h3:nth-child(1) {
          width: 26%;
        }
    
        h3:nth-child(2) {
          padding-left: 6%;
          padding-right: 10%;
        }
      }
    
      .Upload-Body {
        .Upload-Body-Content {
          display: flex;
          padding-bottom: 1.5%;
          align-items: center;
    
         
    
          .FileContent {
            display: flex;
    
           
            .Uploadedfile {
              display: flex;
              background: var(--half-white) 0% 0% no-repeat padding-box;
              border: 1px solid var(--grey-seventh);
              border-radius: 30px;
              width: 24rem;
              height: 3.8rem;
              align-items: center;
              justify-content: center;
              cursor: pointer;
    
              .anticon {
                color: var(--primary);
                opacity: 0.8;
                font-size: var(--text-16);
                align-self: center;
                margin-top: 0;
                margin-right: 0.4rem;
              }
    
              p {
                color: var(--ash-twelve);
                font-weight: 400;
                opacity: 1;
                margin-bottom: 0;
                align-self: center;
                text-transform: lowercase;
                font-size: var(--text-14);
              }
            }
          }
        }
      }
    
      .Patient-Messages-Content {
        h3:nth-child(2) {
          background: var(--half-white) 0% 0% no-repeat padding-box;
          border-radius: 14px;
          padding: 1.25% 1.25% 2.75%;
          line-height: 2.5;
          opacity: 0.8;
          font-size: 1.4rem;
          font-weight: 500;
          color: #333;
        }
      }
    
      .Appointment_MeetingCard {
        .MeetingCard_content {
          .Meetingcard-Btns {
            text-align: center;
            padding-top: 1rem;
    
            .ant-btn {
              width: 16rem;
              height: 3.5rem;
              color: #fff;
              font-size: 1.4rem;
              font-weight: 400;
             
              border-color: #4fb8b6;
              border-radius: 25px;
              
              
            }
            .startBtn{
              background-color: #4fb8b6;
              margin-right: 1.5rem;

            }
            .medicationBtn {
              color: #4fb8b6;
              //background: #4fb8b6;
            }
          }
        }
      }
    }
  }
}
// Appointments-InfoModal ends here
