.Reviews-Container {
  background: #f3f8ff 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  padding: 0 2%;
  // height: 80vh;

  .Reviews-Container-body {
    padding-top: 2%;
    .ant-list-item-meta-description {
      color: rgba(0, 0, 0, 0.45);
      font-size: 1.2rem;
      line-height: 22px;
      width: 85%;
      letter-spacing: 0px;
      color: #5a5a5a;
      // opacity: 0
    }
    .profile-name {
      position: absolute;
      right: 26%;
      padding-top: 11%;
    }
    i.anticon.anticon-eye-invisible {
      font-size: 3.5rem;
    }
    i.anticon.anticon-eye {
      font-size: 3.5rem;
    }
    span.ant-avatar.NoProfileImg.ant-avatar-circle {
    
      background-color: #2D80B5;
  }
  }
}
