@import "../../../../../theme/base/_colors.scss";
.PatientHealthRecords-Collapse {
  padding-bottom: 3rem;
  .ant-collapse {
    border: none;
    border-radius: 15px;
    background-color: var(--white-secondary);
    .ant-collapse-item {
      border-bottom: none;
      background-color: $white;
      border-radius: 15px;
      margin-bottom: 1.4rem;
      .ant-collapse-header {
        border-radius: 15px;
        padding: 3rem 8% 3rem 2.5%;
        .Patient-HealthRecords {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .ant-avatar {
            width: 4.9rem;
            min-width: 4.9rem;
            height: 4.9rem;
            background-color: $primary;
            margin-right: 2%;
            .ant-avatar-string {
              height: 100%;
              display: flex;
              align-items: center;
              font-size: 1.8rem;
            }
          }
          .PatientInfo {
            display: grid;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            padding-left: 0.6rem;
            box-sizing: border-box;
            grid-template-columns: repeat(5, 1fr);
            justify-items: start;
            gap: 2rem;
            h3 {
              color: var(--black);
              letter-spacing: 0.22px;
              opacity: 0.79;
              margin-bottom: 0;
              // width: 22rem;
              // margin-left: 2rem;
              // margin-right: -10rem;
            }
            h3:nth-child(1) {
              padding-bottom: 1.2rem;
            }
            h3.MORE-INFO {
              opacity: 1;
              // text-align: left;
              // margin-left: -2rem;
              .anticon {
                font-size: 1.6rem;
                color: $primary;
              }
              .anticon-check {
                color: var(--black);
              }
            }
          }
        }
        .ant-collapse-arrow {
          right: 2rem;
          left: unset;
        }
      }
      .ant-collapse-content {
        border-top: none;
        border-radius: 0 0 15px 15px;
        .ant-collapse-content-box {
          padding: 0 0 16px;
          h3 {
            color: #232323;
            padding-top: 1rem;
            letter-spacing: 0.22px;
          }
          .PatientHealthRecords-Collapse-Details {
            margin: 0 2.5%;
            .ant-divider-horizontal {
              margin: 0 0 22px;
              background: var(--lightwhite);
            }
            h6 {
              font-size: 1.45rem;
              // color: var(--black);
              padding-bottom: 0.7rem;
              display: flex;
              opacity: 0.9;
              p {
                width: 18rem;
                margin-bottom: 0;
                color: #232323;
                font-weight: 500;
              }
              span:nth-child(2) {
                padding-right: 7rem;
              }
              span:nth-child(3) {
                color: #81837f;
              }
            }
            h4 {
              color: var(--ash);
              padding: 1rem 0 1rem 0.7rem;
              width: 74%;
            }
            h3.ReadMore {
              color: $primary;
              padding-left: 0.7rem;
              padding-top: 0;
              cursor: pointer;
            }
            .Files {
              background: #f3f8ff 0% 0% no-repeat padding-box;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 1.75rem 4.5% 1.7rem 3.75%;
              margin-bottom: 1.4rem;
              opacity: 1;
              .FileContent {
                display: flex;
                align-items: center;
                .ant-avatar {
                  width: 4.6rem;
                  min-width: 4.6rem;
                  height: 4.6rem;
                  line-height: 4.8rem;
                  background-color: $white;
                  margin-right: 8%;
                  .anticon {
                    font-size: 2rem;
                  }
                }
                h3 {
                  opacity: 0.79;
                  margin-bottom: 0;
                  color: #232323;
                  span {
                    font-weight: 500;
                  }
                }
              }
              .anticon {
                color: $primary;
                font-size: 2.4rem;
              }
              .anticon-download {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .ant-pagination {
    float: unset !important;
    text-align: center;
    .ant-pagination-item-active {
      background: #2d80b5;
      border-color: #2d80b5;
      border-radius: 50%;
    }
    .ant-pagination-item a {
      color: var(--black-fourth);
      padding-top: 1px;
      padding-bottom: 1px;
      &:hover {
        color: #2d80b5;
      }
    }
    .ant-pagination-item-active a {
      color: $white;
      &:hover {
        color: $white;
      }
    }
    .ant-pagination-prev {
      margin-right: 1rem !important;
    }
    .ant-pagination-next {
      margin-left: 1rem !important;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      background: $white !important;
      border-radius: 50%;
      box-shadow: 0 0.6px 2px 0.25px;
    }
    .ant-pagination-prev:focus .ant-pagination-item-link,
    .ant-pagination-next:focus .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
      color: var(--black-eight);
    }
    .ant-pagination-item:not(.ant-pagination-item-active) {
      border-radius: 50%;
    }
    .anticon {
      vertical-align: -1px;
      color: var(--ash-fivth);
    }
  }
}

@media only screen and (min-width: 56.25em) {
  .PatientHealthRecords-mobile {
    display: none;
  }
}
@media only screen and (max-width: 56.25em) {
  .PatientHealthRecords-Collapse {
    display: none;
  }
  .PatientHealthRecords-mobile {
    .PatientHealthRecords-setup {
      border-radius: 15px;
      background-color: $white;
      // height: 25rem;
      margin: 2rem 0;
      padding: 3%;
      .PatientInfo {
        display: flex;
        justify-content: space-between;
        .ant-avatar {
          width: 3.5rem;
          min-width: 3.5rem;
          height: 3.5rem;
          background-color: $primary;
          margin-right: 2%;
          .ant-avatar-string {
            height: 100%;
            display: flex;
            align-items: center;
            font-size: 1.8rem;
          }
        }
        h3 {
          padding-left: 1rem;
          padding-top: 0rem;
          font-size: 1.5rem;
        }
        .anticon svg {
          color: $primary;
        }
      }
      .profile__mode__info {
        display: flex;
        justify-content: space-between;
        margin: 2%;
        text-align: left;
        padding-left: 5%;
        h3 {
          font-size: 1.8rem;
          width: 80%;
        }
      }
      .PatientHealthRecords-Collapse-file {
        h3 {
          color: var(--black);
          padding-top: 1rem;
          letter-spacing: 0.22px;
          font-size: 2.5rem;
          text-align: left;
          padding-left: 5%;
        }
        .Files {
          background: var(--white-eleventh) 0% 0% no-repeat padding-box;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 1.75rem 4.5% 1.7rem 3.75%;
          margin-bottom: 1.4rem;
          opacity: 1;
          width: 90%;
          margin-left: 5%;
          .FileContent {
            display: flex;
            align-items: center;
            .ant-avatar {
              width: 4.6rem;
              min-width: 4.6rem;
              height: 4.6rem;
              line-height: 4.8rem;
              background-color: $white;
              margin-right: 8%;
              .anticon {
                font-size: 2rem;
              }
            }
            h3 {
              opacity: 0.79;
              padding-top: 0.5rem;
              font-size: 2.1rem;
            }
          }
          .anticon {
            color: $primary;
            font-size: 2.4rem;
          }
          .anticon-download {
            cursor: pointer;
          }
        }
      }
      .profile__mode__info_data {
        margin: 0 2.5%;
        padding-left: 5%;
        h6 {
          font-size: 1.8rem;
          color: var(--black);
          padding-bottom: 0.7rem;
          display: flex;
          text-align: left;
          opacity: 0.8;
          p {
            width: 25rem;
            margin-bottom: 0;
          }
          span:nth-child(2) {
            padding-right: 7rem;
          }
          span:nth-child(3) {
            text-align: left;
            width: 60%;
            color: var(--ash);
          }
        }
      }
      .profile__mode__info1 {
        text-align: left;
        padding-left: 7.5%;
        h3 {
          color: var(--black);
          font-size: 2.5rem;
        }
        h4 {
          color: var(--black);
          opacity: 0.7;
          font-size: 2rem;
        }
      }
      h3.ReadMore {
        color: #2d80b5;
        padding-left: 0.7rem;
        padding-top: 0;
        cursor: pointer;
        font-family: var(--Medium);
        font-size: 1.8rem;
        margin-top: -0.2rem;
      }
    }
  }
  .insurance-fileds-mobile {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    margin: 2%;
    width: 80%;
    & h3:first-child {
      justify-self: start;
    }
    & h3:last-child {
      justify-self: start;
    }
    @media screen and (max-width: 38em) {
      width: 100%;
      h3 {
        font-size: 1.5rem;
      }
    }
  }
  .PatientHealthRecords-mobile {
    text-align: center;
    .ant-pagination {
      float: unset !important;
      text-align: center;
      .ant-pagination-item-active {
        background: $primary;
        border-color: $primary;
        border-radius: 50%;
      }
      .ant-pagination-item a {
        color: var(--black-fourth);
        padding-top: 1px;
        padding-bottom: 1px;
        font-family: var(--Book);
        &:hover {
          color: $primary;
        }
      }
      .ant-pagination-item-active a {
        color: $white;
        &:hover {
          color: $white;
        }
      }
      .ant-pagination-prev {
        margin-right: 1rem !important;
      }
      .ant-pagination-next {
        margin-left: 1rem !important;
      }
      .ant-pagination-prev .ant-pagination-item-link,
      .ant-pagination-next .ant-pagination-item-link {
        background: $white !important;
        border-radius: 50%;
        box-shadow: 0 0.6px 2px 0.25px;
      }
      .ant-pagination-prev:focus .ant-pagination-item-link,
      .ant-pagination-next:focus .ant-pagination-item-link,
      .ant-pagination-prev:hover .ant-pagination-item-link,
      .ant-pagination-next:hover .ant-pagination-item-link {
        color: var(--black-eight);
      }
      .ant-pagination-item:not(.ant-pagination-item-active) {
        border-radius: 50%;
      }
      .anticon {
        vertical-align: -1px;
        color: var(--ash-fivth);
      }
    }
  }
}

@media only screen and (max-width: 37em) {
  .PatientHealthRecords-mobile {
  }
  .PatientHealthRecords-mobile {
    text-align: left;
    width: 100%;
    .PatientHealthRecords-setup {
      .PatientHealthRecords-Collapse-file {
        margin-left: 1%;
        h3 {
          font-size: 2rem;
          padding-left: 0;
        }
        .Files {
          padding: 1rem 4.5% 1rem 3.75%;
          width: 100%;
          margin-left: 0;
          .FileContent {
            display: flex;
            align-items: center;
            .ant-avatar {
              width: 4.6rem;
              min-width: 4.6rem;
              height: 4.6rem;
              line-height: 4.8rem;
              background-color: $white;
              margin-right: 8%;
              .anticon {
                font-size: 2rem;
              }
            }
            h3 {
              opacity: 0.79;
              padding-top: 0.5rem;
              font-size: 2rem;
            }
          }
          .anticon {
            color: $primary;
            font-size: 2.4rem;
          }
          .anticon-download {
            cursor: pointer;
          }
        }
      }
      .profile__mode__info_data {
        margin: 0% 1%;
        padding-left: 0%;
        h6 {
          font-size: 1.6rem;
          text-align: left;
          p {
            width: 16rem;
            margin-bottom: 0;
          }
          span:nth-child(2) {
            padding-right: 3rem;
          }
          span:nth-child(3) {
            text-align: left;
            width: 60%;
            color: var(--ash);
          }
        }
      }
      .profile__mode__info {
        padding-left: 0;
        h3 {
          font-size: 1.7rem;
        }
      }
      .profile__mode__info1 {
        text-align: left;
        padding-left: 1%;
        h3 {
          font-size: 2rem;
        }
        h4 {
          opacity: 0.7;
          font-size: 1.8rem;
        }
      }
      h3.ReadMore {
        text-align: center;
        font-size: 1.8rem;
      }
    }
    .ant-pagination {
      float: unset !important;
      text-align: center;
      .ant-pagination-item-active {
        background: $primary;
        border-color: $primary;
        border-radius: 50%;
      }
      .ant-pagination-item a {
        font-size: 1.5rem;
        color: var(--black-fourth);
        padding-top: 0px;
        padding-bottom: 1px;
        font-family: var(--Book);
        &:hover {
          color: $primary;
        }
      }
      .ant-pagination-item {
        // width: 2.5rem;
        height: 2.8rem;
      }
      .ant-pagination-item-active a {
        color: $white;
        &:hover {
          color: $white;
        }
      }
      .ant-pagination-prev {
        margin-right: 1rem !important;
      }
      .ant-pagination-next {
        margin-left: 1rem !important;
      }
      .ant-pagination-prev .ant-pagination-item-link,
      .ant-pagination-next .ant-pagination-item-link {
        background: $white !important;
        border-radius: 50%;
        box-shadow: 0 0.6px 2px 0.25px;
      }
      .ant-pagination-prev:focus .ant-pagination-item-link,
      .ant-pagination-next:focus .ant-pagination-item-link,
      .ant-pagination-prev:hover .ant-pagination-item-link,
      .ant-pagination-next:hover .ant-pagination-item-link {
        color: var(--black-eight);
      }
      .ant-pagination-item:not(.ant-pagination-item-active) {
        border-radius: 50%;
      }
      .anticon {
        vertical-align: -1px;
        color: var(--ash-fivth);
      }
    }
  }
}
