@import "../../../../theme/base/_colors.scss";

.DashboardSettings-container {
  .DashboardSettings-body {
    position: relative;
    margin: 1.5% 1.5% 0 2%;
    h3.DashboardSettings-Title {
      color: #4fb8b6;
      font-weight: 600;
      font-size: 2.1rem;
      padding-top: 2.05%;
      padding-bottom: 1.9%;
      cursor: pointer;
    }
    .Preferences-container {
      .Preferences-body {
        padding-left: 1%;
        .PaymentTab-body {
          .Payment-content-one {
            h2 {
              color: $black;
              font-weight: 600;
            }
            h5 {
              color: $dark;
              font-weight: 500;
              font-size: 1.3rem;
            }
          }
          .Add-Payment {
            padding-top: 3%;
            button.ant-btn.Add-Payment-button {
              border-radius: 9px;
              font-weight: 600;
              height: 3.2rem;

              background-color: #4fb8b6;
              border-color: #4fb8b6;
              color: $white;
            }
          }
          .Payment-content-Two {
            padding-top: 5%;
            h2 {
              color: $black;
              font-weight: 600;
            }
            h5 {
              color: $dark;
              font-weight: 500;
              font-size: 1.3rem;
            }
            h5:nth-child(3) {
              color: #4fb8b6;
            }
          }
          .Payment-content-current {
            width: 85%;
            .Payment-content-current-setup {
              display: flex;
              justify-content: space-between;
              padding-top: 3%;
              h4 {
                color: $dark;
              }
            }
            .ant-divider-horizontal {
              display: block;
              clear: both;
              width: 100%;
              min-width: 100%;
              height: 1px;
              margin: 0px;
            }
          }
        }

        .PayoutTab-body {
          .PayoutTab-content-one {
            h2 {
              color: $black;
              font-weight: 600;
            }
            h5 {
              color: $dark;
              font-weight: 500;
              font-size: 1.3rem;
              span {
                color: #4fb8b6;
              }
            }
          }
          .Add-Payment {
            padding-top: 3%;
            button.ant-btn.Add-Payment-button {
              border-radius: 9px;
              font-weight: 600;
              height: 3.2rem;
              background-color: #4fb8b6;
              border-color: #4fb8b6;
              color: $white;
            }
          }
        }

        .TaxesTab-body {
          margin-top: 1%;
          .Taxes-content-one {
            h2 {
              color: $black;
              font-weight: 600;
            }
            h5 {
              color: $dark;
              font-weight: 500;
              font-size: 1.3rem;
            }
          }
          .Add-Payment {
            padding-top: 2.5%;
            button.ant-btn.Add-Payment-button {
              border-radius: 9px;
              font-weight: 600;
              height: 3.2rem;
              background-color: #4fb8b6;
              border-color: #4fb8b6;
              color: $white;
            }
          }
        }

        .Payment-card-setup {
          width: 70%;
          background: $white 0% 0% no-repeat padding-box;
          border: 1px solid $textColor9;
          border-radius: 15px;
          opacity: 1;
          padding: 10% 5%;
          margin-left: 6%;
          .Payment-card-img {
            img {
              width: 4rem;
              margin-bottom: 1rem;
            }
          }
          .Payment-card-info {
            h2 {
              font-size: 2rem;
              font-weight: 600;
              line-height: 20px;
              color: #555555;
            }
            .Payment-card-sub-info {
              padding-top: 3.5%;
              h5 {
                color: $dark;
                font-size: 1.1rem;
              }
              span {
                color: #4fb8b6;
              }
            }
          }
        }

        .Payout-card-setup {
          width: 70%;
          background: $white 0% 0% no-repeat padding-box;
          border: 1px solid $textColor9;
          border-radius: 15px;
          opacity: 1;
          padding: 10% 5%;
          margin-left: 6%;
          .Payout-card-img {
            padding-left: 2%;
            img {
              width: 4rem;
              margin-bottom: 1.5rem;
            }
          }
          .Payout-card-info {
            h2 {
              font-size: 2rem;
              font-weight: 600;
              padding-left: 1%;
              color: #555555;
            }
            h5 {
              line-height: 1;
              color: $dark;
              font-size: 1.14rem;
            }
            span {
              color: #4fb8b6;
            }
          }
          .ant-collapse {
            background-color: unset;
            border: unset;
          }
          .ant-collapse-icon-position-right
            > .ant-collapse-item
            > .ant-collapse-header {
            color: #4fb8b6;
            font-size: 1.3rem;
            font-weight: 500;
          }
          .ant-collapse-content > .ant-collapse-content-box {
            padding: 16px;
            font-size: 1.1rem;
          }
        }

        .Taxes-card-setup {
          width: 70%;
          background: $white 0% 0% no-repeat padding-box;
          border: 1px solid $textColor9;
          border-radius: 15px;
          opacity: 1;
          padding: 10% 5%;
          margin-left: 6%;
          .Taxes-card-img {
            padding-left: 2%;
            img {
              width: 4rem;
              margin-bottom: 1.5rem;
            }
          }
          .Taxes-card-info {
            h2 {
              font-size: 2rem;
              font-weight: 600;
              padding-left: 1%;
              color: #555555;
            }
            .Taxes-card-sub-info {
              padding-top: 3%;
              h5 {
                color: $dark;
                font-size: 1.1rem;
              }
              span {
                color: #4fb8b6;
              }
            }
          }
        }

        .ant-tabs-nav .ant-tabs-tab {
          font-weight: 530;
        }
        .ant-tabs-nav .ant-tabs-tab-active {
          color: #4fb8b6;
        }

        .ant-tabs-nav .ant-tabs-tab:hover {
          color: #4fb8b6;
        }
        .ant-tabs-bar {
          margin: 0 0 16px 0;
          border-bottom: 1px solid #e8e8e8;
          outline: none;
          -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          width: 50%;
        }
        .ant-tabs-ink-bar {
          position: absolute;
          bottom: 1px;
          left: 0;
          z-index: 1;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          width: 0;
          height: 3px;
          background-color: #4fb8b6;
          -webkit-transform-origin: 0 0;
          -ms-transform-origin: 0 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 56.25em) {
  .Preferences-container {
    .anticon.anticon-left {
      display: none;
    }
    .Mobile-faq {
      display: none;
    }
  }
}

@media only screen and (max-width: 56.25em) and (min-width: 600px) {
  .Preferences-container {
    padding: unset;
    .Preferences-body {
      padding: unset;
      .Preferences-Heading {
        h5 {
          color: $dark;
          font-size: 1.2rem;
          display: none;
        }

        .anticon.anticon-left {
          padding-left: 2%;
          font-size: 4rem;
          position: absolute;
          top: unset;
          left: 9%;
          margin-top: 1.7rem;
        }
        h2 {
          color: #4fb8b6;
          position: unset;
          top: 16.5%;
          left: 20%;
          font-weight: 500;
          font-size: 4rem;
          text-align: center;
          z-index: 1000;
          opacity: 0.8;
        }
      }
      .ant-tabs {
        margin-top: 8%;
      }
      .ant-tabs-ink-bar {
        position: absolute;
        bottom: 1px;
        left: 0;
        z-index: 1;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 0;
        height: 3px;
        background-color: #4fb8b6;
        -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        transform-origin: 0 0;
      }

      .ant-tabs-nav-container {
        font-size: 1.9rem;
      }
      .PaymentTab-body {
        .ant-row {
          position: relative;
          height: auto;
          margin-right: 0;
          margin-left: 0;
          zoom: 1;
          display: flex;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          flex-direction: column-reverse;
        }
        .Payment-content-one {
          margin-left: 6%;
          h2 {
            color: $black;
            font-weight: 600;
            font-size: 3.2rem;
          }
          h5 {
            color: $dark;
            font-weight: 500;
            font-size: 2.2rem;
            width: 80%;
          }
        }

        .Add-Payment {
          padding-top: 3%;
          button.ant-btn.Add-Payment-button {
            border-radius: 15px;
            font-weight: 500;
            height: 7rem;
            width: 30rem;
            font-size: 2.2rem;
            background-color: #4fb8b6;
            border-color: #4fb8b6;
            color: $white;
          }
        }

        .Payment-content-Two {
          margin-left: 6%;
          padding-top: 5%;
          h2 {
            color: $black;
            font-weight: 600;
            font-size: 3.2rem;
          }
          h5 {
            color: $dark;
            font-weight: 500;
            font-size: 2.2rem;
            width: 85%;
          }
          h5:nth-child(3) {
            color: #4fb8b6;
          }
        }
        .Payment-content-current {
          margin-left: 6%;
          width: 85%;
          margin-bottom: 8%;
          .Payment-content-current-setup {
            display: flex;
            justify-content: space-between;
            padding-top: 3%;
            h4 {
              color: $dark;
              font-size: 2.2rem;
            }
          }
          .ant-divider-horizontal {
            display: block;
            clear: both;
            width: 100%;
            min-width: 100%;
            height: 1px;
            margin: 0px;
          }
        }
      }

      .PayoutTab-body {
        margin-bottom: 8%;
        .ant-row {
          position: relative;
          height: auto;
          margin-right: 0;
          margin-left: 0;
          zoom: 1;
          display: flex;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          flex-direction: column-reverse;
        }
        .PayoutTab-content-one {
          margin-left: 7.4%;
          h2 {
            color: $black;
            font-weight: 600;
            font-size: 3.3rem;
          }
          h5 {
            color: $dark;
            font-weight: 500;
            font-size: 2.3rem;
            width: 80%;
            span {
              color: #4fb8b6;
            }
          }
        }

        .Add-Payment {
          padding-top: 3%;
          button.ant-btn.Add-Payment-button {
            border-radius: 15px;
            font-weight: 500;
            height: 7rem;
            width: 30rem;
            font-size: 2.2rem;
            background-color: #4fb8b6;
            border-color: #4fb8b6;
            color: $white;
          }
        }
      }

      .TaxesTab-body {
        margin-bottom: 8%;
        margin-left: 6%;
        .ant-row {
          position: relative;
          height: auto;
          margin-right: 0;
          margin-left: 0;
          zoom: 1;
          display: flex;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          flex-direction: column-reverse;
        }
        .Taxes-content-one {
          h2 {
            color: $black;
            font-weight: 600;
            font-size: 3.3rem;
          }
          h5 {
            color: $dark;
            font-weight: 500;
            font-size: 2.4rem;
            margin: 0;
            width: 85%;
          }
        }
        .Add-Payment {
          padding-top: 4%;
          button.ant-btn.Add-Payment-button {
            border-radius: 15px;
            font-weight: 500;
            height: 7rem;
            width: 30rem;
            font-size: 2.2rem;
            background-color: #4fb8b6;
            border-color: #4fb8b6;
            color: $white;
          }
        }
      }

      .Payment-card-setup {
        width: 70%;
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 10px 40px #71717129;
        border: 1px solid $white;
        border-radius: 15px;
        opacity: 1;
        padding: 4% 6%;
        margin-bottom: 8%;
        position: relative;
        left: 16%;
        .Payment-card-img {
          img {
            width: 5rem;
            margin-bottom: 1rem;
          }
        }
        .Payment-card-info {
          h2 {
            font-weight: 600;
            line-height: unset;
            color: #555555;
            font-size: 3rem;
            line-height: 1;
          }
          .Payment-card-sub-info {
            padding-top: 1%;

            h5 {
              color: $dark;
              font-size: 2rem;
            }
            span {
              color: #4fb8b6;
            }
          }
        }
      }

      .Payout-card-setup {
        width: 60%;
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 10px 40px #71717129;
        border: 1px solid $white;
        border-radius: 15px;
        opacity: 1;
        padding: 4% 6%;
        margin-bottom: 8%;
        position: relative;
        left: 20%;
        .faq-mobile-setup {
          display: flex;
          .Mobile-faq {
            margin-left: 8%;
            margin-top: 12%;
            img {
              width: 6rem;
              margin-bottom: 1.5rem;
            }
          }
        }

        .Payout-card-info {
          .web-faq {
            display: none;
          }
          h2 {
            font-size: 3rem;
            font-weight: 600;
            padding-left: 1%;
            color: #555555;
          }
          h5 {
            line-height: 1;
            color: $dark;
            font-size: 1.14rem;
          }
          span {
            color: #4fb8b6;
          }
        }
        .ant-collapse {
          background-color: unset;
          border: unset;
        }
        .ant-collapse-icon-position-right
          > .ant-collapse-item
          > .ant-collapse-header {
          color: #4fb8b6;
          font-size: 1.6rem;
          font-weight: 500;
        }
        .ant-collapse-content > .ant-collapse-content-box {
          padding: 16px;
          font-size: 1.1rem;
        }
      }

      .Taxes-card-setup {
        width: 70%;
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 10px 40px #71717129;
        border: 1px solid $white;
        border-radius: 15px;
        opacity: 1;
        padding: 4% 6%;
        margin-bottom: 8%;
        position: relative;
        left: 14%;
        .Taxes-card-img {
          padding-left: 2%;
          img {
            width: 6rem;
            margin-bottom: 1.5rem;
          }
        }
        .Taxes-card-info {
          h2 {
            font-size: 3rem;
            font-weight: 600;

            color: #555555;
          }
          .Taxes-card-sub-info {
            padding-top: 3%;
            h5 {
              color: $dark;
              font-size: 2rem;
              line-height: unset;
            }
            span {
              color: #4fb8b6;
            }
          }
        }
      }
      .ant-tabs-nav-scroll {
        overflow: hidden;
        white-space: nowrap;
      }
      .ant-tabs-nav .ant-tabs-tab {
        font-weight: 500;
        margin-left: 14%;
        font-size: 3.2rem;
      }
      .ant-tabs-nav .ant-tabs-tab-active {
        color: #4fb8b6;
        opacity: 1;
      }
      .ant-tabs-nav .ant-tabs-tab:hover {
        color: #4fb8b6;
      }
      .ant-tabs-tab {
        color: $dark;
        opacity: 0.5;
      }
      .ant-tabs-bar {
        margin: 0 0 16px 0;
        border-bottom: 1px solid #e8e8e8;
        outline: none;
        -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: unset;
      }
    }
  }
}

@media only screen and (max-width: 37.5em) {
  .Preferences-container {
    padding: unset;
    .Preferences-body {
      padding: unset;
      .Preferences-Heading {
        h5 {
          color: $dark;
          font-size: 1.2rem;
          display: none;
        }
        .anticon.anticon-left {
          padding-left: 2%;
          font-size: 2.6rem;
          position: absolute;
          top: 17.5%;
          left: 9%;
        }
        h2 {
          color: #4fb8b6;
          position: absolute;
          top: 16.5%;
          left: 20%;
          font-weight: 500;
          font-size: 2.6rem;
          text-align: center;
          z-index: 1000;
          opacity: 0.8;
        }
      }
      .ant-tabs {
        margin-top: 8%;
      }
      .ant-tabs-ink-bar {
        position: absolute;
        bottom: 1px;
        left: 0;
        z-index: 1;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 0;
        height: 3px;
        background-color: #4fb8b6;
        -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        transform-origin: 0 0;
      }

      .ant-tabs-nav-container {
        font-size: 1.9rem;
      }
      .PaymentTab-body {
        .ant-row {
          position: relative;
          height: auto;
          margin-right: 0;
          margin-left: 0;
          zoom: 1;
          display: flex;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          flex-direction: column-reverse;
        }
        .Payment-content-one {
          margin-left: 6%;
          h2 {
            color: $black;
            font-weight: 600;
            font-size: 1.8rem;
          }
          h5 {
            color: $dark;
            font-weight: 500;
            font-size: 1.3rem;
            width: 80%;
          }
        }

        .Add-Payment {
          padding-top: 3%;
          button.ant-btn.Add-Payment-button {
            border-radius: 15px;
            font-weight: 500;
            height: 5rem;
            font-size: 1.6rem;
            background-color: #4fb8b6;
            border-color: #4fb8b6;
            color: $white;
          }
        }

        .Payment-content-Two {
          margin-left: 6%;
          padding-top: 5%;
          h2 {
            color: $black;
            font-weight: 600;
            font-size: 1.8rem;
          }
          h5 {
            color: $dark;
            font-weight: 500;
            font-size: 1.3rem;
            width: 85%;
          }
          h5:nth-child(3) {
            color: #4fb8b6;
          }
        }
        .Payment-content-current {
          margin-left: 6%;
          width: 85%;
          margin-bottom: 8%;
          .Payment-content-current-setup {
            display: flex;
            justify-content: space-between;
            padding-top: 3%;
            h4 {
              color: $dark;
            }
          }
          .ant-divider-horizontal {
            display: block;
            clear: both;
            width: 100%;
            min-width: 100%;
            height: 1px;
            margin: 0px;
          }
        }
      }

      .PayoutTab-body {
        margin-bottom: 8%;
        .ant-row {
          position: relative;
          height: auto;
          margin-right: 0;
          margin-left: 0;
          zoom: 1;
          display: flex;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          flex-direction: column-reverse;
        }
        .PayoutTab-content-one {
          margin-left: 7.4%;
          h2 {
            color: $black;
            font-weight: 600;
          }
          h5 {
            color: $dark;
            font-weight: 500;
            font-size: 1.3rem;
            width: 80%;
            span {
              color: #4fb8b6;
            }
          }
        }

        .Add-Payment {
          padding-top: 3%;
          button.ant-btn.Add-Payment-button {
            border-radius: 15px;
            font-weight: 600;
            height: 5rem;
            font-size: 1.6rem;
            background-color: #4fb8b6;
            border-color: #4fb8b6;
            color: $white;
          }
        }
      }

      .TaxesTab-body {
        margin-bottom: 8%;
        margin-left: 6%;
        .ant-row {
          position: relative;
          height: auto;
          margin-right: 0;
          margin-left: 0;
          zoom: 1;
          display: flex;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          flex-direction: column-reverse;
        }
        .Taxes-content-one {
          h2 {
            color: $black;
            font-weight: 600;
            font-size: 1.8rem;
          }
          h5 {
            color: $dark;
            font-weight: 500;
            font-size: 1.3rem;
            margin: 0;
            width: 85%;
          }
        }
        .Add-Payment {
          padding-top: 4%;
          button.ant-btn.Add-Payment-button {
            border-radius: 25px;
            font-weight: 500;
            height: 5rem;
            font-size: 1.4rem;
            background-color: #4fb8b6;
            border-color: #4fb8b6;
            color: $white;
          }
        }
      }

      .Payment-card-setup {
        width: 90%;
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 10px 40px #71717129;
        border: 1px solid $white;
        border-radius: 15px;
        opacity: 1;
        padding: 4% 6%;
        margin-left: 6%;
        margin-bottom: 8%;
        .Payment-card-img {
          img {
            width: 4rem;
            margin-bottom: 1rem;
          }
        }
        .Payment-card-info {
          h2 {
            font-weight: 600;
            line-height: unset;
            color: #555555;
            font-size: 1.8rem;
            line-height: 1;
          }
          .Payment-card-sub-info {
            padding-top: 1%;
            h5 {
              color: $dark;
              font-size: 1.2rem;
            }
            span {
              color: #4fb8b6;
            }
          }
        }
      }

      .Payout-card-setup {
        width: 90%;
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 10px 40px #71717129;
        border: 1px solid $white;
        border-radius: 15px;
        opacity: 1;
        padding: 4% 6%;
        margin-left: 6%;
        margin-bottom: 8%;
        .faq-mobile-setup {
          display: flex;
          .Mobile-faq {
            margin-left: 8%;
            margin-top: 12%;
            img {
              width: 4rem;
              margin-bottom: 1.5rem;
            }
          }
        }

        .Payout-card-info {
          .web-faq {
            display: none;
          }
          h2 {
            font-size: 2rem;
            font-weight: 600;
            padding-left: 1%;
            color: #555555;
          }
          h5 {
            line-height: 1;
            color: $dark;
            font-size: 1.14rem;
          }
          span {
            color: #4fb8b6;
          }
        }
        .ant-collapse {
          background-color: unset;
          border: unset;
        }
        .ant-collapse-icon-position-right
          > .ant-collapse-item
          > .ant-collapse-header {
          color: #4fb8b6;
          font-size: 1.3rem;
          font-weight: 500;
        }
        .ant-collapse-content > .ant-collapse-content-box {
          padding: 16px;
          font-size: 1.1rem;
        }
      }

      .Taxes-card-setup {
        width: 90%;
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 10px 40px #71717129;
        border: 1px solid $white;
        border-radius: 15px;
        opacity: 1;
        padding: 4% 6%;
        margin-bottom: 8%;
        .Taxes-card-img {
          padding-left: 2%;
          img {
            width: 4rem;
            margin-bottom: 1.5rem;
          }
        }
        .Taxes-card-info {
          h2 {
            font-size: 2rem;
            font-weight: 600;
            padding-left: 1%;
            color: #555555;
          }
          .Taxes-card-sub-info {
            padding-top: 3%;
            h5 {
              color: $dark;
              font-size: 1.28rem;
            }
            span {
              color: #4fb8b6;
            }
          }
        }
      }

      .ant-tabs-nav .ant-tabs-tab {
        font-weight: 530;
      }
      .ant-tabs-nav .ant-tabs-tab-active {
        color: #4fb8b6;
        opacity: 1;
      }
      .ant-tabs-nav .ant-tabs-tab:hover {
        color: #4fb8b6;
      }
      .ant-tabs-tab {
        color: $dark;
        opacity: 0.5;
      }
      .ant-tabs-bar {
        margin: 0 0 16px 0;
        border-bottom: 1px solid #e8e8e8;
        outline: none;
        -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: unset;
      }
    }
  }
}

@media only screen and (max-width: 600px) and (min-width: 400px) {
  .Preferences-container .ant-tabs-nav .ant-tabs-tab {
    font-weight: 500;
    font-size: 2.4rem;
  }
}
