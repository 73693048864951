@import "../../../../theme/base/_colors.scss";

.Dashboard-SideContent {
  position: fixed;
  padding-left: 3rem;
  width: 21.5vw;
  right: 0.8rem;
  @media screen and (max-width: 75em) {
    right: 0.3rem;
  }

  // Dashboard-SideContent-DoctorInfo starts here
  .Dashboard-SideContent-DoctorInfo {
    background: #f3f8ff 0% 0% no-repeat padding-box;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    text-align: center;
    margin-bottom: 3.5rem;
    .DoctorInfo-Icons {
      display: flex;
      justify-content: flex-end;
      padding: 1rem 1.4rem 1rem 0;
      .ant-badge-dot {
        width: 1.15rem;
        height: 1.15rem;
      }
      .anticon {
        font-size: 3rem;
        color: #4fb8b6;
        cursor: pointer;
      }
    }
    .DoctorInfo-Details {
      cursor: pointer;
      .NoProfileImg {
        background-color: #5fbfbd;
        .ant-avatar-string {
          font-size: 7rem;
          bottom: 0%;
        }
      }
    }
    h1 {
      font-size: 1.8rem;
      font-weight: 500;
      color: #2d80b5;
      margin-bottom: -0.4rem;
      padding-top: 1rem;
      text-transform: capitalize;
    }
    p {
      font-size: 1.6rem;
      font-weight: 500;
      //letter-spacing: 0.99px;
      color: #4fb8b6;
      padding-top: 1rem;
      padding-bottom: 3.2rem;
    }
  }
  // Dashboard-SideContent-DoctorInfo ends here

  // Dashboard-SideContent-Chat starts here
  .Dashboard-SideContent-Chat {
    background: #f3f8ff 0% 0% no-repeat padding-box;
    border-radius: 30px 30px 0px 0px;
    .SideContent-ChatTitle {
      position: relative;
      h3 {
        color: #4fb8b6;
        text-align: center;
        font-size: 2rem;
        font-weight: 600;
        padding-top: 9.25%;
        .anticon {
          color: #4fb8b6;
          position: relative;
          left: 20%;
          cursor: pointer;
        }
      }
      .ChatDivider {
        background-color: #4fb8b6;
        margin: 0;
        height: 0.4rem;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        width: 50%;
        min-width: 50%;
        left: 21%;
        right: 0;
      }
      .divider2 {
        background-color: #4fb8b6;
        margin: 0;
      }
    }
    .SideContent-Chat-Details {
      position: relative;
      display: flex;
      padding: 8% 8% 0;
      .ChatContent {
        align-self: center;
        margin-left: 0.8rem;
        h4 {
          color: $black;
          opacity: 0.6;
          font-size: 1.6rem;
          font-weight: 600;
          padding: 0.4rem 0;
          margin-bottom: 0;
        }
        p {
          color: $black;
          opacity: 0.37;
          font-size: 1.1rem;
        }
      }
      .ant-badge-not-a-wrapper:not(.ant-badge-status) {
        position: absolute;
        right: 1.2rem;
        padding-top: 0.65rem;
      }
      .ant-badge-count {
        width: 1.8rem;
        min-width: 1.8rem;
        height: 1.8rem;
        font-size: 1.1rem;
        padding: 0;
      }
    }
  }
  // Dashboard-SideContent-Chat ends here
}
