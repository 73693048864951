@import "../../theme/base/_colors.scss";

.Remote-Video {
  width: 100% !important;
  height: 99vh !important;
}

.room {
  background-color: #111;
  .local-participant {
    .participant {
      position: relative;
      .Local-Video {
        position: absolute;
        width: 17.5%;
      }
    }
  }
  .Call-Btns-Content {
    background-color: $white;
    position: fixed;
    width: 100%;
    height: 8rem;
    bottom: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 2000;
    button.ant-btn {
      width: 5rem;
      height: 5rem;
      margin: 0 1rem;
      cursor: pointer;
      .anticon {
        font-size: 2.2rem;
        padding: 1.4rem 0;
        color: $secondary;
      }
      &:hover {
        box-shadow: 0 1px 3px 0 #d9d9d9, 0 4px 8px 3px #d9d9d9;
      }
    }
    button.ant-btn.audio,
    button.ant-btn.logout,
    button.ant-btn.video {
      background-color: $white;
      border-color: #d9d9d9;
      &:hover {
        border-color: #d9d9d9;
      }
    }
    button.ant-btn.logout {
      .anticon-phone {
        color: #ff4d4f;
      }
    }
    button.ant-btn.audio2,
    button.ant-btn.video2 {
      background-color: #ff4d4f;
      border-color: #ff4d4f;
      .anticon {
        color: $white;
      }
      &:hover {
        border-color: #ff4d4f;
      }
    }
    .Screenshare {
      position: absolute;
      right: 2%;
      text-align: center;
      cursor: pointer;
      .anticon-up-square {
        font-size: 2rem;
      }
      h4 {
        padding-top: 0.2rem;
        font-size: 1.35rem;
        margin-bottom: 0;
        color: $secondary;
      }
    }
  }
}
.videocall_spin{
  text-align: center;
  .ant-spin-spinning {
    position: absolute;
    z-index: 1000;
    left: 45%;
    top: 43%;
    .ant-spin-dot {
      font-size: 23px;
      color: var(--primary);
      opacity: 0.7;
    }
  }
  .ant-spin-text{
    color: var(--primary);
    font-size: 2rem;
    font-weight: 600;
  }
  h2{
    position: absolute;
    z-index: 1000;
    left: 45%;
    top: 43%;
    color: #1890ff;
    font-size: 2.5rem;
    font-weight: 600;
  }

}
