@import "../../../../theme/base/_colors.scss";

.DashboardSettings-container {
  .DashboardSettings-body {
    position: relative;
    margin: 1.5% 1.5% 0 2%;
    h3.DashboardSettings-Title {
      color: #4fb8b6;
      font-weight: 600;
      font-size: 2.1rem;
      padding-top: 2.05%;
      padding-bottom: 1.9%;
      cursor: pointer;
    }
    .Payouts-Container {
      overflow: hidden;
      .Payouts-body-content {
        margin-right: 4rem;
        .Payouts-Body-Edit {
          width: 96%;
          h3 {
            font-weight: 600;
            margin-bottom: 0;
          }
          .Payouts-Edit {
            color: #4fb8b6;
            position: relative;
            text-align: right;
            bottom: 2.3rem;
            font-size: 1.1em;
            cursor: pointer;
          }
          .Payouts-Content {
            color: $black;
            opacity: 0.5;
            margin-top: -1.4rem;
            font-size: 1em;
          }
          .Payouts-EditContent2 {
            display: none;
            .Payouts-Content-Select {
              font-size: 1em;
              font-weight: 600;
              color: $secondary;
              width: 100%;
              margin: 1.4rem 0;
              .ant-select-selection--single {
                height: 40px;
                padding: 0.5rem 0;
                border-color: #80808021;
              }
              .ant-select-arrow {
                color: $black;
              }
            }
            .Payouts-Content-btn {
              border-radius: 10px;
              font-weight: 500;
              font-size: 1.2rem;
              background-color: #4fb8b6;
              color: $white;
              margin-bottom: 2rem;
            }
          }
          .Payouts-divider {
            margin: 2rem 0 2.6rem;
          }

          .edit-input {
            margin-bottom: 2%;
            margin-top: 1%;
            button.ant-btn {
              margin-top: 1rem;
              background-color: #4fb8b6;
              color: $white;
              border-color: #4fb8b6;
              border-radius: 8px;
            }
          }
        }

        .Payouts-Preferences {
          margin-top: 3.4rem;
          .ant-card-bordered {
            border: 2px solid #e8e8e8;
            border-radius: 10px;
          }
          .ant-card-body {
            padding: 3.4rem 2.6rem 2rem;
          }
          img {
            width: 3rem;
          }
          p {
            font-weight: 600;
            font-size: 1.7rem;
            opacity: 0.6;
            margin-top: 1.2rem;
            margin-bottom: 3.6rem;
          }
          .Payouts-Preferences-Content {
            font-weight: 600;
            color: $secondary;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 56.25em) {
  .Payouts-Container {
    .anticon.anticon-left {
      display: none;
    }
  }
}

@media only screen and (max-width: 56.25em) and (min-width: 600px) {
  .Payouts-Container {
    overflow: hidden;
    .Payouts-body-content {
      margin: 1rem;
      .ant-row {
        position: relative;
        height: auto;
        margin-right: 0;
        margin-left: 0;
        zoom: 1;
        display: flex;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        flex-direction: column-reverse;
      }
      p:nth-child(1) {
        display: none;
      }
      .Payouts-Body-Edit {
        width: 80%;
        margin-left: 10%;
        h3 {
          font-weight: 600;
          margin-bottom: 0;
          font-size: 3rem;
        }
        .Payouts-Edit {
          color: #4fb8b6;
          position: relative;
          text-align: right;
          bottom: 2.3rem;
          font-size: 3rem;
          cursor: pointer;
        }
        .Payouts-Content {
          color: $black;
          opacity: 0.5;
          margin-top: -1.4rem;
          font-size: 2.4rem;
        }
        .Payouts-EditContent2 {
          display: none;
          .Payouts-Content-Select {
            font-size: 1em;
            font-weight: 600;
            color: $secondary;
            width: 100%;
            margin: 1.4rem 0;
            .ant-select-selection--single {
              height: 40px;
              padding: 0.5rem 0;
              border-color: #80808021;
            }
            .ant-select-arrow {
              color: $black;
            }
          }
          .Payouts-Content-btn {
            border-radius: 10px;
            font-weight: 500;
            font-size: 1.2rem;
            background-color: #4fb8b6;
            color: $white;
            margin-bottom: 2rem;
          }
        }
        .Payouts-divider {
          margin: 2rem 0 2.6rem;
        }

        .edit-input {
          margin-bottom: 2%;
          margin-top: 1%;
          button.ant-btn {
            margin-top: 1rem;
            background-color: #4fb8b6;
            color: $white;
            border-color: #4fb8b6;
            border-radius: 8px;
          }
        }
      }

      .Payouts-Preferences {
        margin-top: 3.4rem;
        .ant-card-bordered {
          width: 100%;
          background: $white 0% 0% no-repeat padding-box;
          box-shadow: 0px 10px 40px #71717129;
          border: 1px solid $white;
          border-radius: 15px;
          opacity: 1;
          padding: 8% 2%;
          padding-bottom: 7%;
          margin-left: 6%;
          margin-bottom: 12%;
          position: relative;
          left: 24%;
        }
        .ant-card-body {
          padding-left: 2rem;
          padding-top: 1rem;
          padding-bottom: 2rem;
        }
        img {
          width: 6rem;
        }
        p {
          font-weight: 600;
          font-size: 3rem;
          opacity: 1;
          margin-top: 1.2rem;
          margin-bottom: 0.1rem;
          color: #555555;
        }
        .Payouts-Preferences-Content {
          font-weight: 600;
          color: $secondary;
          font-size: 1.8rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 37.5em) {
  .Payouts-Container {
    overflow: hidden;
    .Payouts-body-content {
      margin: 1rem;
      .ant-row {
        position: relative;
        height: auto;
        margin-right: 0;
        margin-left: 0;
        zoom: 1;
        display: flex;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        flex-direction: column-reverse;
      }
      p:nth-child(1) {
        display: none;
      }
      .Payouts-Body-Edit {
        width: 88%;
        margin-left: 5%;
        h3 {
          font-weight: 600;
          margin-bottom: 0;
        }
        .Payouts-Edit {
          color: #4fb8b6;
          position: relative;
          text-align: right;
          bottom: 2.3rem;
          font-size: 1.1em;
          cursor: pointer;
        }
        .Payouts-Content {
          color: $black;
          opacity: 0.5;
          margin-top: -1.4rem;
          font-size: 1em;
        }
        .Payouts-EditContent2 {
          display: none;
          .Payouts-Content-Select {
            font-size: 1em;
            font-weight: 600;
            color: $secondary;
            width: 100%;
            margin: 1.4rem 0;
            .ant-select-selection--single {
              height: 40px;
              padding: 0.5rem 0;
              border-color: #80808021;
            }
            .ant-select-arrow {
              color: $black;
            }
          }
          .Payouts-Content-btn {
            border-radius: 10px;
            font-weight: 500;
            font-size: 1.2rem;
            background-color: #4fb8b6;
            color: $white;
            margin-bottom: 2rem;
          }
        }
        .Payouts-divider {
          margin: 2rem 0 2.6rem;
        }

        .edit-input {
          margin-bottom: 2%;
          margin-top: 1%;
          button.ant-btn {
            margin-top: 1rem;
            background-color: #4fb8b6;
            color: $white;
            border-color: #4fb8b6;
            border-radius: 8px;
          }
        }
      }
      .Payouts-Preferences {
        margin-top: 3.4rem;
        .ant-card-bordered {
          width: 90%;
          background: $white 0% 0% no-repeat padding-box;
          box-shadow: 0px 10px 40px #71717129;
          border: 1px solid $white;
          border-radius: 15px;
          opacity: 1;
          padding: 2% 2%;
          margin-left: 6%;
          margin-bottom: 8%;
        }
        .ant-card-body {
          padding-left: 2rem;
          padding-top: 1rem;
          padding-bottom: 2rem;
        }
        img {
          width: 3rem;
        }
        p {
          font-weight: 600;
          font-size: 1.7rem;
          opacity: 1;
          margin-top: 1.2rem;
          margin-bottom: 0.1rem;
          color: #555555;
        }
        .Payouts-Preferences-Content {
          font-weight: 600;
          color: $secondary;
          font-size: 1.2rem;
        }
      }
    }
  }
}
