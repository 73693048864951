@import "../../../theme/base/_colors.scss";

.ConfirmRegister-Body {
  padding-top: 3%;
  margin: 0 4rem;
  .ant-col-lg-11 {
    text-align: right;
    margin-right: 2.5rem;
  }
  .ant-col-lg-12 {
    display: flex;
    padding-top: 12.6rem;
  }
  .ConfirmRegister-HeaderImg {
    margin-bottom: 7rem;
    text-align: center;
    img {
      width: 24rem;
    }
  }
  .ConfirmRegister-Image {
    width: 100%;
    img {
      width: 108%;
      max-width: 66.74rem;
    }
  }
  .ConfirmRegister-Content {
    h1 {
      color: $primary;
      font-size: 2.85rem;
      margin-bottom: 2.5rem;
      padding-left: 4.8rem;
      text-align: center;
    }
    h1:nth-child(1) {
      margin-bottom: 0.3rem;
      margin-top: 2.6rem;
    }
    p {
      font-weight: 500;
      color: #81837f;
      text-align: center;
      font-size: 1.6rem;
    }
    .Register-Content {
      display: flex;
      justify-content: center;
     // padding-top: 6rem;
      padding-right: 19rem;
      .RegisterDetails {
        padding-top: 8.75rem;
        h2 {
          color: #9a9c99;
          font-size: 1.95rem;
        }
        h3 {
          color: #262626;
          font-weight: 600;
          font-size: 1.75rem;
          span {
            font-weight: 500;
            font-size: 1.36rem;
          }
        }
      }
      .Register-Image {
        img {
          width: 28rem;
          position: absolute;
        }
      }
    }
  }
}