@import "../../../theme/base/_colors.scss";

.ProfileInfo-Container {
  position: relative;
  .ProfileInfo-Container-Headerlogo {
    padding: 1.5% 0 2.5% 10.25%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    img {
      width: 26rem;
    }
    // ProfileInfo-dot-setup starts here
    .ProfileInfo-dot-setup {
      display: flex;
      justify-content: center;
      position: relative;
      right: 26%;
      .disable-dot {
        margin-right: 0.5rem;
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        background-color: #4fb8b676;
      }
      .active-dot {
        margin-right: 0.5rem;
        height: 1rem;
        width: 3.5rem;
        background-color: #4fb8b6;
        border-radius: 25px;
      }
    }
    // ProfileInfo-dot-setup ends here
  }

  .ProfileInfo-Container-Heading {
    padding-top: 4%;
    text-align: center;
    width: 80%;
    h3 {
      color: $secondary;
      font-size: 1.7rem;
      text-transform: none;
      font-weight: 400;
    }
  }
  .ProfileInfo-Container-Img {
    padding-left: 11%;
    padding-top: 3%;
    position: relative;
    bottom: 4%;
    width: 100%;
    img {
      width: 71%;
    }
    img.MyInfoImg {
      width: 59%;
    }
    img.EducationImg {
      width: 71%;
    }
    img.AccomplishmentImg {
      width: 65%;
    }
  }
  .ant-spin-spinning {
    display: inline-block;
    opacity: 1;
    text-align: center;
    position: absolute;
    top: 80%;
    right: 100%;
    color: #4fb8b680;
    z-index: 1000;
  }
  .NextBTN {
    text-align: center;
    padding-top: 8%;
    cursor: pointer;
    img {
      width: 4.5rem;
    }
  }

  .ProfileInfo-Container-Details {
    // PhotoAvatar body starts here
    .PhotoAvatar-Body {
      text-align: center;
      padding-top: 2%;
      padding-right: 24%;
      h1 {
        color: #363d58;
        font-size: 3.2rem;
        padding-bottom: 0.2rem;
        padding-right: 16%;
      }
      .PhotoAvatar-Body-UploadImg {
        background: $white 0% 0% no-repeat padding-box;
        border: 2px dashed #707070;
        margin-right: 16%;
        .ant-upload-list {
          display: none;
        }
        .ant-upload {
          padding-top: 23%;
        }
        h2 {
          color: #4fb8b6;
          font-size: 2.5rem;
          font-weight: 600;
          cursor: pointer;
          text-transform: capitalize;
        }
        h3 {
          color: $secondary;
          font-size: 2.1rem;
          padding-top: 1.5%;
          padding-bottom: 30%;
          text-transform: lowercase;
        }
        p {
          color: $secondary;
          font-weight: 500;
          font-size: 1.42rem;
          margin-bottom: 0;
        }
        p:nth-child(5) {
          padding-bottom: 9%;
        }
      }
      .ant-spin.SPIN {
        top: 20rem;
        left: 30%;
        .ant-spin-dot-item {
          background-color: #4fb8b6;
        }
      }
      .PhotoAvatar-Body-UploadImg2 {
        background: $white 0% 0% no-repeat padding-box;
        margin-right: 16%;
        .ant-upload-list {
          display: none;
        }
        .ant-upload {
          padding-top: 7%;
          padding-bottom: 13%;
        }
        button.ant-btn {
          border: 3.5px solid #4fb8b6;
          border-radius: 30px;
          color: #4fb8b6;
          font-weight: 500;
          font-size: 1.85rem;
          width: 22rem;
          height: 4rem;
          &:hover,
          &:focus {
            border-color: #4fb8b6;
          }
        }
        p {
          color: $secondary;
          font-weight: 500;
          font-size: 1.42rem;
          margin-bottom: 0;
        }
        p:nth-child(6) {
          padding-bottom: 12%;
        }
        img.btnImg {
          width: 4.5rem;
          cursor: pointer;
        }
      }
      .anticon.ForwardIcon {
        color: #4fb8b6;
        font-size: 3.4rem;
        padding-top: 10%;
        float: right;
        cursor: pointer;
      }
    }
    // PhotoAvatar body ends here

    // MyInformation body starts here
    .MyInformation-Body {
      padding-right: 6%;
      h3 {
        color: $textColor2;
        text-align: center;
        font-size: 2.5rem;
        padding-bottom: 2rem;
        text-transform: capitalize;
      }
      .MyInformation-Body-Details {
        display: flex;
        align-items: baseline;
        position: relative;
        h4 {
          color: $textColor7;
          font-size: 1.6rem;
          .star {
            color: #f44336;
            padding-right: 2%;
            font-size: 1.8rem;
          }
        }
        h4:nth-child(1) {
          width: 26%;
        }
        h4:nth-child(2) {
          padding-right: 4%;
        }
        input.ant-input,
        textarea.ant-input,
        .ant-select-selection--multiple,
        .ant-select-selection--single {
          color: $textColor7;
          background: $white 0% 0% no-repeat padding-box;
          border: 0.30000001192092896px solid $textColor8;
          border-radius: 12px;
          box-shadow: none;
          &:hover,
          &:focus {
            border-color: $textColor8;
          }
        }
        .InputCustom {
          width: 56%;
          padding-bottom: 0.15rem;
          input.ant-input {
            height: 4.4rem;
            margin-bottom: 0.3rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .ant-spin.SpinContent {
          margin-top: -1rem;
          .ant-spin-dot-item {
            background-color: #4fb8b6;
          }
        }
        .ant-select {
          width: 56%;
          padding-bottom: 1.8rem;
          margin-top: auto;
          .ant-select-selection--multiple,
          .ant-select-selection--single {
            min-height: 4.4rem;
            padding-bottom: 9px;
          }
          .ant-select-selection--multiple .ant-select-selection__rendered,
          .ant-select-selection--single .ant-select-selection__rendered {
            padding-top: 0.35rem;
            min-height: 3.6rem;
          }
          .ant-select-selection--multiple > ul > li,
          .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
            margin-top: 0.6rem;
          }
          .ant-select-selection--multiple .ant-select-selection__choice {
            color: #4fb8b6;
            background-color: #4fb8b612;
            border: 1px solid #4fb8b6;
            border-radius: 10px;
          }
          .ant-select-selection__placeholder,
          .ant-select-search__field__placeholder {
            top: 60%;
          }
        }
        .Textarea-Details {
          display: flex;
          flex-direction: column;
          width: 56%;
          align-items: baseline;
          textarea.ant-input {
            width: 100%;
          }
          .ant-select.SpecialitySelect {
            width: 100%;
            margin-top: unset;
          }
        }
        textarea.ant-input {
          width: 56%;
          margin-bottom: 1.85rem;
        }
        .anticon.PLUS,
        .anticon.UPLOAD {
          color: #4fb8b6;
          font-size: 2.2rem;
          cursor: pointer;
        }
        .anticon.PLUS {
          position: absolute;
          right: 6%;
          bottom: 3rem;
        }
      }
      .ERROR {
        color: #f44336;
        font-size: 1.1rem;
        padding-left: 32%;
        margin-top: -1.8rem;
        margin-bottom: 0.2rem;
      }
      .NextBTN {
        text-align: center;
        padding: 6% 0;
        cursor: pointer;
        img {
          width: 4.5rem;
        }
      }
    }
    // MyInformation body ends here

    // Education body starts here
    .Education-Body {
      padding-right: 6%;
      .ant-spin-spinning {
        top: 30%;
      }
      h3 {
        color: $textColor2;
        text-align: center;
        font-size: 2.5rem;
        padding-bottom: 10%;
        padding-top: 7%;
      }
      .Education-Body-Details {
        display: flex;
        align-items: flex-start;
        position: relative;
        h4 {
          color: $textColor7;
          font-size: 1.6rem;
          padding-top: 0.25rem;
          .star {
            color: #f44336;
            padding-right: 2%;
            font-size: 1.8rem;
          }
        }
        h4.NoTextarea {
          padding-top: 1rem;
        }
        h4:nth-child(1) {
          width: 26%;
        }
        h4:nth-child(2) {
          padding-right: 4%;
        }
        input.ant-input,
        textarea.ant-input {
          color: $textColor7;
          background: $white 0% 0% no-repeat padding-box;
          border: 0.30000001192092896px solid $textColor8;
          border-radius: 12px;
          box-shadow: none;
          &:hover,
          &:focus {
            border-color: $textColor8;
          }
        }
        .InputCustom {
          width: 56%;
          padding-bottom: 0.5rem;
          input.ant-input {
            height: 4.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .Textarea-Details {
          display: flex;
          flex-direction: column;
          width: 56%;
          align-items: baseline;
          textarea.ant-input {
            width: 100%;
            margin-bottom: 1.85rem;
          }
          textarea.ant-input.TextAreaInput {
            border-color: #4fb8b6;
            box-shadow: 0px 0px 4px #4fb8b6;
          }
          .Inner-Textarea-Details {
            width: 100%;
            position: relative;
            .anticon-minus-circle {
              position: absolute;
              color: #ff4d4f;
              font-size: 2.2rem;
              right: -13%;
              top: 17%;
              z-index: 0;
              cursor: pointer;
            }
          }
        }
        .Location-Details {
          width: 56%;
          padding-bottom: 1.95rem;
          .google-places-autocomplete__input {
            width: 100%;
            height: 4.5rem;
            padding: 4px 11px;
            color: $textColor7;
            font-size: 1.3rem;
            line-height: 1.5;
            background-color: $white;
            background-image: none;
            border: 0.30000001192092896px solid $textColor8;
            border-radius: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            outline: none;
            &:hover,
            &:focus {
              border-color: $textColor8;
            }
          }
          .PinIMG {
            position: absolute;
            right: 14%;
            top: 0.8rem;
            width: 1.5rem;
          }
          .google-places-autocomplete__suggestions-container {
            position: absolute;
            top: 4.5rem;
            background-color: $white;
            border-radius: 15px;
            box-shadow: 0 2px 20px $textColor8;
            z-index: 1000;
            .google-places-autocomplete__suggestion {
              padding: 0.4rem 1rem 0.4rem 1.5rem;
              cursor: pointer;
            }
            .google-places-autocomplete__suggestion:nth-child(1) {
              padding-top: 0.6rem;
            }
            .google-places-autocomplete__suggestion:nth-last-child(1) {
              padding-bottom: 0.6rem;
            }
            .google-places-autocomplete__suggestion:hover {
              background-color: #4fb8b6;
              color: $white;
              border-radius: 2px;
            }
          }
        }
        .anticon.PLUS,
        .anticon.UPLOAD {
          color: #4fb8b6;
          font-size: 2.2rem;
          cursor: pointer;
        }
        .anticon.PLUS {
          position: absolute;
          right: 6%;
          bottom: 3.65rem;
          z-index: 1;
        }
        .anticon.UPLOAD {
          padding-left: 28%;
          padding-top: 10%;
        }
      }
      .ERROR {
        color: #f44336;
        font-size: 1.1rem;
        padding-left: 32%;
        margin-top: -1.85rem;
        margin-bottom: 0.25rem;
      }
      .NextBTN {
        text-align: center;
        padding: 6% 0;
        cursor: pointer;
        img {
          width: 4.5rem;
        }
      }
    }
    // Education body ends here

    // Accomplishment body starts here
    .Accomplishment-Body {
      padding-right: 6%;
      h3 {
        color: $textColor2;
        text-align: center;
        font-size: 2.5rem;
        padding-bottom: 4%;
        padding-top: 14%;
      }
      .Accomplishment-Body-Details {
        display: flex;
        align-items: flex-start;
        position: relative;
        h4 {
          color: $textColor7;
          font-size: 1.6rem;
          padding-top: 0.25rem;
          .star {
            color: #f44336;
            padding-right: 2%;
            font-size: 1.8rem;
          }
        }
        h4.NoTextarea {
          padding-top: 1rem;
        }
        h4:nth-child(1) {
          width: 26%;
        }
        h4:nth-child(2) {
          padding-right: 4%;
        }
        input.ant-input,
        textarea.ant-input {
          color: $textColor7;
          background: $white 0% 0% no-repeat padding-box;
          border: 0.30000001192092896px solid $textColor8;
          border-radius: 12px;
          box-shadow: none;
          &:hover,
          &:focus {
            border-color: $textColor8;
          }
        }
        .InputCustom {
          width: 56%;
          padding-bottom: 0.8rem;
          input.ant-input {
            height: 4.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .InputCustom2 {
          width: 100%;
          padding-bottom: 0.8rem;
          input.ant-input {
            height: 4.5rem;
            width: 100%;
            border-color: #4fb8b6;
            box-shadow: 0px 0px 4px #4fb8b6;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .ant-upload-list {
          display: none;
        }
        .Textarea-Details {
          display: flex;
          flex-direction: column;
          width: 56%;
          align-items: baseline;
          textarea.ant-input {
            width: 100%;
            margin-bottom: 2.15rem;
          }
          textarea.ant-input.TextAreaInput {
            border-color: #4fb8b6;
            box-shadow: 0px 0px 4px #4fb8b6;
          }
          .ant-spin.SpinContent {
            margin-top: -1rem;
            .ant-spin-dot-item {
              background-color: #4fb8b6;
            }
          }
          .InputCustom {
            width: 100%;
          }
          .Inner-Textarea-Details {
            width: 100%;
            position: relative;
            .anticon-minus-circle {
              position: absolute;
              color: #ff4d4f;
              font-size: 2.2rem;
              right: -13%;
              top: 17%;
              z-index: 0;
              cursor: pointer;
            }
          }
        }
        .anticon.PLUS,
        .anticon.UPLOAD {
          color: #4fb8b6;
          font-size: 2.2rem;
          cursor: pointer;
        }
        .anticon.PLUS {
          position: absolute;
          right: 6%;
          bottom: 3.7rem;
        }
        .anticon.UPLOAD {
          padding-left: 28%;
          padding-top: 10%;
        }
      }
      .ERROR {
        color: #f44336;
        font-size: 1.1rem;
        padding-left: 32%;
        margin-top: -2rem;
        margin-bottom: 0.4rem;
      }
      .NextBTN {
        text-align: center;
        padding-top: 8%;
        padding-bottom: 6%;
        cursor: pointer;
        img {
          width: 4.5rem;
        }
      }
    }
    // Accomplishment body ends here
  }
}

.ERROR1 {
  color: #f44336;
  font-size: 1.1rem;
  padding-left: 2%;
  margin-top: -2rem;
  margin-bottom: 0.4rem;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item-selected:hover
  .ant-select-selected-icon {
  color: #4fb8b6 !important;
  opacity: 1 !important;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #4fb8b612 !important;
}
