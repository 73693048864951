@import "../../../theme/base/_colors.scss";

.DashboardPatientList-Body {
  position: relative;
  margin: 0 1.5% 0 2%;
  // border: 2px solid black;
  //margin-top: 2rem;  //changed by pavan
  h3 {
    text-transform: capitalize;
    color: #4fb8b6;
    font-weight: 500;
    font-size: 1.8rem;
    padding-top: 2.05%;
    padding-bottom: 1.9%;
  }
  .DashboardPatientList-ProfileInfo {
    position: absolute;
    right: 0;
    top: 0;
    width: 20rem;
    background: #f3f8ff 0% 0% no-repeat padding-box;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2% 1.25% 1.25%;
    h3 {
      color: #4fb8b6;
      font-weight: 500;
      font-size: 1.6rem;
      padding: 0;
      margin-bottom: 0;
      text-transform: capitalize;
      cursor: pointer;
    }
    .ant-badge-dot {
      width: 1.15rem;
      height: 1.15rem;
    }
    .anticon {
      font-size: 3rem;
      color: #4fb8b6;
      cursor: pointer;
    }
  }
  .RadioStatus-Details {
    padding-bottom: 1.4rem;
    span.ant-radio + * {
      // padding-right: 2rem;
      font-size: 1.4rem;
      font-weight: 500;
      color: #4fb8b6;
    }
    .ant-radio-group {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .ant-radio-wrapper {
        display: flex;
        align-items: center;
        span {
          color: $black;
          opacity: 0.5;
        }
      }
      .ant-radio-wrapper-checked {
        span {
          color: #4fb8b6;
          opacity: 1;
        }
      }
      .ant-radio-checked .ant-radio-inner {
        border-color: #4fb8b6;
        opacity: 1;
      }
      .ant-radio-inner {
        border-color: #4fb8b6;
        opacity: 1 !important;
      }
      .ant-radio-inner::after {
        background-color: #4fb8b6;
        opacity: 1;
      }
      .ant-radio-wrapper:hover .ant-radio,
      .ant-radio:hover .ant-radio-inner,
      .ant-radio-input:focus + .ant-radio-inner {
        border-color: #4fb8b6;
        opacity: 1;
      }
    }
  }
  .DashboardPatientList-Filters {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2%;
    .ant-select-selection {
      border: 1px solid #eaeaea;
      border-radius: 14px;
      height: 4rem;
      width: 17rem;
      &:hover {
        border-color: #eaeaea;
      }
    }
    .ant-select-selection-selected-value {
      color: $secondary;
      font-size: 1.5rem;
      font-weight: 500;
      padding-top: 0.4rem;
    }
    .DEFAULT {
      display: flex;
      justify-content: flex-end;
      padding: 0.8rem 1rem 0.5rem 0;
      cursor: pointer;
      img {
        width: 2.3rem;
        height: 2rem;
        margin-top: 0.2rem;
      }
      p {
        color: #575757;
        font-weight: 500;
        font-size: 1.55rem;
        padding-left: 1.6rem;
      }
    }
  }

  .DashboardPatientList-Body-Table {
    margin-top: 3rem; //changed by pavan
    .ant-spin-spinning {
      .ant-spin-dot-item {
        background-color: #4fb8b6;
      }
    }
    .ant-table-thead > tr:first-child > th:first-child {
      border-top-left-radius: 8px;
    }
    .ant-table-thead > tr > th {
      background: #4fb8b6 0% 0% no-repeat padding-box;
      color: $white;
      font-size: 1.4rem;
      font-weight: 500;
      text-align: center;
      padding-bottom: 1.5rem;
      text-transform: capitalize;
    }
    .ant-table-thead > tr:first-child > th:last-child {
      border-top-right-radius: 8px;
    }
   
    .ant-table-tbody > tr > td {
      font-size: 1.4rem;
      font-weight: 400;
      text-align: center;
      text-transform: capitalize;
    }
    .ant-table-tbody > tr > td:nth-child(5) {
      font-size: 1.4rem; //changed by pavan
      font-weight: 400;
     // color: #333333;
      text-transform: lowercase;
      // height: 7.8rem;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      border-bottom: 1px solid #4fb8b666;
    }

    .ant-table-tbody > tr:last-child > td {
      border-bottom: none;
    }
    .ant-table-body {
      border-radius: 8px;
      background: #f3f8ff 0% 0% no-repeat padding-box;
    }
    .ant-table-placeholder {
      border-top: none;
      border-radius: 20px;
    }
  }

  .PatientInfo-Container {
    .PatientInfo-Body {
      display: flex;
      border-radius: 22px;
      background-color: #f3f8ff;
      padding: 3rem;
      margin-top: 2.6rem;
      .PatientInfo-Body-Avatar {
        padding-right: 3rem;
        .ant-avatar {
          margin-top: 1rem;
          background-color: #4fb8b6;
          .ant-avatar-string {
            font-size: 7rem;
            text-transform: uppercase;
          }
        }
        .ChatIcon {
          width: 2.6rem;
          height: 2.3rem;
          margin-top: 2rem;
          margin-left: 4.3rem;
        }
      }
      .PatientInfo-Body-Details {
        width: 38%;
        .details {
          display: flex;
          align-items: center;

          h3 {
            color: $black;
            opacity: 0.6;
            font-size: 1.4rem;
            margin-bottom: 0.5rem;
            font-weight: 500;
          }
          h3 {
            width: 9rem;
          }
          h4 {
            font-size: 1.4rem;
            font-weight: 500;
            color: #333333;
            margin-top: 0.3rem;
            padding-left: 5%;
          }
          .patientName{
            text-transform: capitalize;
          }
        }
      }
      .ant-divider,
      .ant-divider-vertical {
        width: 0.3px;
        height: 12rem;
        background-color: #4fb8b6;
        margin-right: 5%;
      }
    }
    .ant-list-split .ant-list-item {
      border-bottom: 0px solid #e8e8e8;
    }
    .ant-list {
      // width: 59%;
    }

    .ant-list-item {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 12px 0;
      // width: 50%;
    }
    .PatientInfo-Filters {
      // display: flex;
      // justify-content: space-between;
      padding-top: 1.7%;
      h3 {
        padding: 0.2rem 0 0;
      }
      .DEFAULT {
        display: flex;
        justify-content: flex-end;
        padding: 0.8rem 1rem 0.5rem 0;
        cursor: pointer;
        img {
          width: 2.3rem;
          height: 2rem;
          margin-top: 0.2rem;
        }
        p {
          color: #575757;
          font-weight: 500;
          font-size: 1.55rem;
          padding-left: 1.6rem;
        }
      }
      .HealthProfile-Content,
      .HealthProfile-Content1 {
        .ant-list-item {
          display: grid;
          grid-template-columns: 1fr 0.5fr 1fr;
          width: 68%;
          margin-bottom: 0;
          @media screen and (min-width: 112em) {
            width: 50%;
          }
          // justify-content: space-between;
          h5 {
            color: #232323;
            text-transform: capitalize;
            opacity: 0.7;
            font-size: 1.4rem;
            font-weight: 500;
            // width: 25%;
          }
          span {
            margin-right: 5%;
          }
          h4 {
            font-size: 1.4rem;
            font-weight: 500;
            color: #333;
            text-transform: capitalize;
          }
          .email_text{
            text-transform: lowercase;
          }
        }
      }
      .HealthProfile-Content1 {
        .ant-col-xl-8 {
          width: 68%;
          .ant-list-item {
            // background-color: #232323;
            width: 100% !important;
          }
        }
      }
      .ant-tabs {
        .ant-tabs-bar {
          margin: 0;
          border-bottom: none;
          padding: 0 2.25%;
          .ant-tabs-nav {
            .ant-tabs-tab {
              padding: 1rem 0.6rem 1.6rem;
              font-size: 1.5rem;
              color: #333333;
              text-transform: capitalize;
              opacity: 0.5;
              font-weight: 500;
            }
            .ant-tabs-tab-active,
            .ant-tabs-tab:hover {
              color: #4fb8b6;
              opacity: 1;
            }
            .ant-tabs-ink-bar {
              height: 0.3rem;
              background: #4fb8b6;
              border-radius: 30px;
            }
          }
        }
        .ant-tabs-content {
          .ant-tabs-tabpane {
            // background-color: #f3f8ff;
            padding: 2.4rem 2.5% 0;
          }
        }
      }
    }
    .PatientInfo-Body-Table {
      .ant-spin-spinning {
        .ant-spin-dot-item {
          background-color: #4fb8b6;
        }
      }
      .ant-table-thead > tr:first-child > th:first-child {
        border-top-left-radius: 25px;
      }
      .ant-table-thead > tr > th {
        background: #4fb8b6 0% 0% no-repeat padding-box;
        color: $white;
        font-size: 1.6rem;
        font-weight: 400;
        padding-bottom: 1.5rem;
      }
      .ant-table-thead > tr:first-child > th:last-child {
        border-top-right-radius: 25px;
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        text-align: center;
      }
      .ant-table-tbody > tr > td {
        font-size: 1.65rem;
        font-weight: 600;
        color: #333333;
        height: 8.8rem;
        text-transform: capitalize;
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        border-bottom: 1px solid #4fb8b666;
      }
      .ant-table-tbody > tr:last-child > td {
        border-bottom: none;
      }
      .ant-table-body {
        // height: 80vh;
        border-radius: 25px;
        background: #f3f8ff 0% 0% no-repeat padding-box;
      }
    }
  }
}

.VIEW {
  color: #4fb8b6;
  font-weight: 500;
  font-size: 1.6rem;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 0.2rem;
}
.TEXT1 {
  font-size: 1.5rem;
  font-weight: 400;
  color: #4fb8b6;
  text-decoration: underline;
  cursor: pointer;
}
.ChatIcon {
  width: 2.5rem;
  height: 2.1rem;
  cursor: pointer;
}

// PatientInfo_Modal starts here
.Appointments-InfoModal {
  width: 100rem !important;
  .ant-modal-content {
    border-radius: 8px;
    // background-color: #f3f8ff;
    margin-top: 2rem;
    ::-webkit-scrollbar {
      width: 0.8rem;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #4fb8b6;
      border-radius: 10rem;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #4fb8b6;
      border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: #4fb8b6;
    }
    ::-webkit-scrollbar-button {
      width: 50px;
    }
  }
  .ant-modal-body {
    padding: 0;
    position: relative;
    max-height: 94vh;
    overflow: auto;
    .IconClose {
      position: sticky;
      color: #4fb8b6;
      font-size: 3rem;
      float: right;
      right: 1.5rem;
      top: 1.5rem;
      cursor: pointer;
    }

    .AppointmentDetails_Container {
      padding: 4% 3% 2% 4%;

      h3 {
        font-size: 1.6rem;
        font-weight: 500;
        color: #4fb8b6;
        padding: 1% 0;
      }
      .viewAppointment_details {
        .view_booking_content {
          width: 100%;
          display: grid;
          grid-template-columns: 48% 48%;
          grid-column-gap: 4%;
          //padding: 0 2%;

          .view_booking_info {
            display: grid;
            grid-template-columns: 35% 5% 60%;
            width: 100%;
            margin-bottom: 1.2rem;
            overflow-wrap: anywhere;

            h5 {
              font-size: 1.4rem;
              opacity: 0.8;
              font-weight: 500;
              margin-bottom: 0;
              text-transform: capitalize;
            }

            span {
              margin-right: 5%;
            }

            h4 {
              font-size: 1.4rem;
              color: #333;

              font-weight: 500;
              margin-bottom: 0;
              text-transform: capitalize;
              span {
                padding-left: 1%;
              }
            }
            a {
              font-size: 2.6rem;
              margin-left: 15px;
              color: #2d80b5;
            }
            .email_text {
              text-transform: lowercase;
            }
          }
        }
      }
      .Basic_Questions {
        h4 {
          font-size: 1.4rem;
          color: #333;
          opacity: 0.8;
          font-weight: 500;
        }

        .Questions-Content,
        .Questions-Content1 {
          display: flex;
          align-items: baseline;
          // grid-template-columns: repeat(4, 20%);
          flex-wrap: wrap;
          align-items: baseline;
          padding-top: 0.2rem;
          padding-bottom: 2.5%;

          .anticon-up-cirlce {
            font-size: 0.5rem;
            color: #2d80b5;
          }

          h5 {
            padding-left: 0.8rem;
            font-size: 1.4rem;
            font-weight: 500;
            color: #333;
          }

          .anticon svg {
            color: #67696c;
            background-color: #67696c;
            border-radius: 50%;
            font-size: 1.1rem;
          }
          font-size: 1.1rem;

          h3 {
            padding-left: 1.3%;
            margin-right: 9.5%;
            font-size: 1.4rem;
          }
        }

        .questions-Content-data {
          display: grid;
          width: 100%;
          grid-template-columns: 20% 20% 20% 20%;

          h5 {
            span {
              margin-left: 1rem;
            }
          }
        }
      }

      // overflow-y: auto;

      .Details-Content {
        display: flex;
        padding-bottom: 3.5%;

        h3:nth-child(1) {
          width: 26%;
        }

        h3:nth-child(2) {
          padding-left: 6%;
          padding-right: 10%;
        }
      }

      .Upload-Body {
        .Upload-Body-Content {
          display: flex;
          padding-bottom: 1.5%;
          align-items: center;

          .FileContent {
            display: flex;

            .Uploadedfile {
              display: flex;
              background: var(--half-white) 0% 0% no-repeat padding-box;
              border: 1px solid var(--grey-seventh);
              border-radius: 30px;
              width: 24rem;
              height: 3.8rem;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              .anticon {
                color: var(--primary);
                opacity: 0.8;
                font-size: var(--text-16);
                align-self: center;
                margin-top: 0;
                margin-right: 0.4rem;
              }

              p {
                color: var(--ash-twelve);
                font-weight: 400;
                opacity: 1;
                margin-bottom: 0;
                align-self: center;
                text-transform: lowercase;
                font-size: var(--text-14);
              }
            }
          }
        }
      }

      .Patient-Messages-Content {
        h3:nth-child(2) {
          background: var(--half-white) 0% 0% no-repeat padding-box;
          border-radius: 14px;
          padding: 1.25% 1.25% 2.75%;
          line-height: 2.5;
          opacity: 0.8;
          font-size: 1.4rem;
          font-weight: 500;
          color: #333;
        }
      }

      .Appointment_MeetingCard {
        .MeetingCard_content {
          .Meetingcard-Btns {
            text-align: center;
            padding-top: 1rem;

            .ant-btn {
              width: 16rem;
              height: 3.5rem;
              color: #fff;
              font-size: 1.4rem;
              font-weight: 400;

              border-color: #4fb8b6;
              border-radius: 25px;
            }
            .startBtn {
              background-color: #4fb8b6;
              margin-right: 1.5rem;
            }
            .medicationBtn {
              color: #4fb8b6;
              //background: #4fb8b6;
            }
          }
        }
      }
    }
  }
}
// PatientInfo_Modal ends here

.PatientInfo_Modal {
  width: 65% !important;
  .ant-modal-content {
    border-radius: 8px;
    // background-color: #f3f8ff;
    margin-top: 2rem;
    ::-webkit-scrollbar {
      width: 0.8rem;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #4fb8b6;
      border-radius: 10rem;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #4fb8b6;
      border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: #4fb8b6;
    }
    ::-webkit-scrollbar-button {
      width: 50px;
    }
  }
  .ant-modal-body {
    padding: 0;
    position: relative;
    max-height: 94vh;
    overflow: auto;
    .IconClose {
      position: sticky;
      color: #4fb8b6;
      font-size: 3rem;
      float: right;
      right: 1.5rem;
      top: 1.5rem;
      cursor: pointer;
    }
    .Patient-DetailsView-Body {
      .AppointmentDetails_Container {
        padding: 4% 3% 2% 4%;

        h3 {
          font-size: 1.6rem;
          font-weight: 500;
          color: #4fb8b6;
          padding: 1% 0;
        }
        .viewAppointment_details {
          .view_booking_content {
            width: 100%;
            display: grid;
            grid-template-columns: 48% 48%;
            grid-column-gap: 4%;
            //padding: 0 2%;

            .view_booking_info {
              display: grid;
              grid-template-columns: 35% 5% 60%;
              width: 100%;
              margin-bottom: 1.2rem;
              overflow-wrap: anywhere;

              h5 {
                font-size: 1.4rem;
                opacity: 0.8;
                font-weight: 500;
                margin-bottom: 0;
                text-transform: capitalize;
              }

              span {
                margin-right: 5%;
              }

              h4 {
                font-size: 1.4rem;
                color: #333;

                font-weight: 500;
                margin-bottom: 0;
                text-transform: capitalize;
                span {
                  padding-left: 1%;
                }
              }
              a {
                font-size: 2.6rem;
                margin-left: 15px;
                color: #2d80b5;
              }
              .email_text {
                text-transform: lowercase;
              }
            }
          }
        }
        .Basic_Questions {
          h4 {
            font-size: 1.4rem;
            color: #333;
            opacity: 0.8;
            font-weight: 500;
          }
  
          .Questions-Content,
          .Questions-Content1 {
            display: flex;
            align-items: baseline;
            // grid-template-columns: repeat(4, 20%);
            flex-wrap: wrap;
            align-items: baseline;
            padding-top: 0.2rem;
            padding-bottom: 2.5%;
  
            .anticon-up-cirlce {
              font-size: 0.5rem;
              color: #2d80b5;
            }
  
            h5 {
              padding-left: 0.8rem;
              font-size: 1.4rem;
              font-weight: 500;
              color: #333;
            }
  
            .anticon svg {
              color: #67696c;
              background-color: #67696c;
              border-radius: 50%;
              font-size: 1.1rem;
            }
            font-size: 1.1rem;
  
            h3 {
              padding-left: 1.3%;
              margin-right: 9.5%;
              font-size: 1.4rem;
            }
          }
  
          .questions-Content-data {
            display: grid;
            width: 100%;
            grid-template-columns: 20% 20% 20% 20%;
  
            h5 {
              span {
                margin-left: 1rem;
              }
            }
          }
        }

        // overflow-y: auto;

        .Details-Content {
          display: flex;
          padding-bottom: 3.5%;

          h3:nth-child(1) {
            width: 26%;
          }

          h3:nth-child(2) {
            padding-left: 6%;
            padding-right: 10%;
          }
        }

        .Upload-Body {
          .Upload-Body-Content {
            display: flex;
            padding-bottom: 1.5%;
            align-items: center;

            .FileContent {
              display: flex;

              .Uploadedfile {
                display: flex;
                background: var(--half-white) 0% 0% no-repeat padding-box;
                border: 1px solid var(--grey-seventh);
                border-radius: 30px;
                width: 24rem;
                height: 3.8rem;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                .anticon {
                  color: var(--primary);
                  opacity: 0.8;
                  font-size: var(--text-16);
                  align-self: center;
                  margin-top: 0;
                  margin-right: 0.4rem;
                }

                p {
                  color: var(--ash-twelve);
                  font-weight: 400;
                  opacity: 1;
                  margin-bottom: 0;
                  align-self: center;
                  text-transform: lowercase;
                  font-size: var(--text-14);
                }
              }
            }
          }
        }

        .Patient-Messages-Content {
          h3:nth-child(2) {
            background: var(--half-white) 0% 0% no-repeat padding-box;
            border-radius: 14px;
            padding: 1.25% 1.25% 2.75%;
            line-height: 2.5;
            opacity: 0.8;
            font-size: 1.4rem;
            font-weight: 500;
            color: #333;
          }
        }

        .Appointment_MeetingCard {
          .MeetingCard_content {
            .Meetingcard-Btns {
              text-align: center;
              padding-top: 1rem;

              .ant-btn {
                width: 16rem;
                height: 3.5rem;
                color: #fff;
                font-size: 1.4rem;
                font-weight: 400;

                border-color: #4fb8b6;
                border-radius: 25px;
              }
              .startBtn {
                background-color: #4fb8b6;
                margin-right: 1.5rem;
              }
              .medicationBtn {
                color: #4fb8b6;
                //background: #4fb8b6;
              }
            }
          }
        }
      }
    }
    .Patient-MedicationView-Body{
      .Patient-MedicationView-Body-Table{
        margin: 6rem 2rem;
      }
    }
  }
}

// Table pagination starts here
.ant-table-pagination.ant-pagination {
  float: unset !important;
  text-align: center;
  .ant-pagination-item-active {
    background: #4fb8b6;
    border-color: #4fb8b6;
    border-radius: 50%;
  }
  .ant-pagination-item a {
    color: #222222;
    padding-top: 1px;
    padding-bottom: 1px;
    &:hover {
      color: #4fb8b6;
    }
  }
  .ant-pagination-item-active a {
    color: $white;
    &:hover {
      color: $white;
    }
  }
  .ant-pagination-prev {
    margin-right: 1rem !important;
  }
  .ant-pagination-next {
    margin-left: 1rem !important;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background: $white !important;
    border-radius: 50%;
    box-shadow: 0 0.6px 2px 0.25px;
  }
  .ant-pagination-item:not(.ant-pagination-item-active) {
    border-radius: 50%;
  }
  .anticon {
    vertical-align: -1px;
    color: #bfbfbf;
  }
}
// Table pagination ends here
