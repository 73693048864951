@import "../../../theme/base/_colors.scss";

.DashboardSettings-Container {
  .DashboardSettings-body {
    position: relative;
    margin: 1.5% 1.5% 0 2%;
    h3 {
      color: #4fb8b6;
      font-weight: 500;
      font-size: 1.8rem;
      padding-top: 2.05%;
      padding-bottom: 1.9%;
      .anticon-right{
        font-size: 1rem;
        font-weight: 500;
      }
      span{
       // font-size: 1.6rem;
      }
    }
    .DashboardSettings-ProfileInfo {
      position: absolute;
      right: 0;
      top: 0;
      width: 30rem;
      background: #f3f8ff 0% 0% no-repeat padding-box;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2% 1.25% 1.25%;
      h3 {
        color: $primary;
        font-weight: 600;
        font-size: 2.2rem;
        padding: 0;
        margin-bottom: 0;
        text-transform: capitalize;
      }
      .ant-badge-dot {
        width: 1.15rem;
        height: 1.15rem;
      }
      .anticon {
        font-size: 3rem;
        color: #4fb8b6;
        cursor: pointer;
      }
    }
    .DashboardSettings-Cards-Body {
      padding: 3% 2% 1% 2%;
      .DashboardSettings-Cards-Row {
        padding-bottom: 0.4rem;
        .DashboardSettings-Card {
          background: $white 0% 0% no-repeat padding-box;
          box-shadow: 0px 10px 40px #71717129;
          border-radius: 15px;
          opacity: 1;
          padding: 2.2rem 2.7rem 3.3rem;
          width: 92%;
          margin-bottom: 4.5%;
          cursor: pointer;
          .DashboardSettings-Card-img {
            padding-left: 1rem;
            img {
              width: 3.5rem;
              margin-bottom: 1rem;
            }
          }
          .DashboardSettings-Card-info {
            h3 {
              color: #4fb8b6;
              font-size: 1.6rem;
              font-weight: 600;
              padding: 0;
            }
            h5 {
              line-height: 1.5;
              color: $secondary;
              font-size: 1.3rem;
              font-weight: 400;
            }
          }
        }
      }
    }
    .DashboardSettings-active {
      padding-top: 3.4rem;
      text-align: center;
      h4 {
        color: $secondary;
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }
      h5 {
        color: #4fb8b6;
        font-size: 1.55rem;
        font-weight: 600;
      }
    }
  }
}

@media only screen and (min-width: 56.25em) {
  .DashboardSettings-Container {
    .anticon.anticon-left {
      display: none;
    }
  }
}
@media only screen and (max-width: 56.25em) and (min-width: 600px) {
  .DashboardSettings-Container {
    .DashboardSettings-body {
      padding-top: 2%;
      padding-left: 7%;
      padding-right: 6%;
      .DashboardSettings-Cards-Body {
        padding-top: 15%;
        .DashboardSettings-Cards-Row {
          padding-bottom: 1.5%;
          .DashboardSettings-Card {
            background: $white 0% 0% no-repeat padding-box;
            box-shadow: 0px 10px 40px #71717129;
            border-radius: 15px;
            opacity: 1;
            padding: 4rem 6rem;
            width: 100%;
            margin-bottom: 3%;
            position: relative;
            left: 16%;
            .DashboardSettings-Card-img {
              img {
                width: 8rem;
                margin-bottom: 1rem;
              }
            }
            .DashboardSettings-Card-info {
              h3 {
                font-size: 3.2rem;
                font-weight: 600;
                color: $dark;
              }
              h5 {
                line-height: 1;
                color: $dark;
                font-size: 2.4rem;
              }
            }
          }
        }
      }
      .DashboardSettings-active {
        padding-top: 5%;
        padding-right: 5%;
        text-align: center;
        h4 {
          color: $dark;
          text-align: inherit;
          font-size: 2.6rem;
        }
        h5 {
          color: #4fb8b6;
          font-size: 2rem;
          text-align: inherit;
        }
      }
    }
  }
}

@media only screen and (max-width: 37.5em) {
  .DashboardSettings-Container {
    .DashboardSettings-body {
      padding-top: 3%;
      padding-left: 7%;
      padding-right: 6%;
      .DashboardSettings-Cards-Body {
        padding-top: 5%;
        .DashboardSettings-Cards-Row {
          padding-bottom: 1.5%;
          .DashboardSettings-Card {
            background: $white 0% 0% no-repeat padding-box;
            box-shadow: 0px 10px 40px #71717129;
            border-radius: 15px;
            opacity: 1;
            padding: 4rem 2.5rem;
            width: 95%;
            margin-bottom: 3%;
            .DashboardSettings-Card-img {
              img {
                width: 5rem;
                margin-bottom: 1rem;
              }
            }
            .DashboardSettings-Card-info {
              h3 {
                font-size: 1.8rem;
                font-weight: 600;
                color: $black;
                opacity: 0.7 !important;
              }
              h5 {
                line-height: 1;
                color: $dark;
                font-size: 1.6rem;
              }
            }
          }
        }
      }
      .DashboardSettings-active {
        padding-top: 5%;
        padding-right: 5%;
        text-align: center;
        h4 {
          color: $dark;
          text-align: inherit;
        }
        h5 {
          color: #4fb8b6;
          font-size: 1.3rem;
          text-align: inherit;
        }
      }
    }
  }
}
