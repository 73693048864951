@import "../../../theme/base/_colors.scss";

// HelpSupportPage starts here
.DashboardHelpSupport-Container {
  height: 100vh;
  text-align: center;
  padding: 3% 2.15% 0;
  h1 {
    color: #363d58;
    font-size: 2.26rem;
    position: relative;
    padding-bottom: 2%;
    button.ant-btn {
      position: absolute;
      right: 0;
      bottom: 2.2rem;
      background: #4fb8b6 0% 0% no-repeat padding-box;
      border-color: #4fb8b6;
      border-radius: 20px;
      font-size: 1.56rem;
      font-weight: 500;
      width: 16rem;
      height: 4.5rem;
      text-transform: uppercase;
      opacity: 1;
      &:hover,
      &:focus {
        border-color: #4fb8b6;
      }
    }
  }
  .ant-input-affix-wrapper {
    width: 58rem;
    input.ant-input {
      background: $white 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 33px #a2a2a229;
      border: 0.30000001192092896px solid #e8e8e8;
      border-radius: 12px;
      font-size: 1.5rem;
      height: 4.6rem;
      color: #232323;
      letter-spacing: 0.2px;
      opacity: 1;
      &:hover,
      &:focus {
        border-color: #4fb8b6;
      }
    }
    .ant-input:not(:first-child) {
      padding-left: 4.4rem;
    }
    .anticon {
      color: #a2a0a0;
      font-size: 2rem;
    }
  }
  img {
    width: 46rem;
    padding-top: 4.5%;
  }
  .DashboardHelpSupport-Content {
    padding: 3.5% 8% 0 5%;
    h3.HelpSupport-SubTitle {
      color: #363d58;
      font-size: 1.6rem;
      font-weight: 600;
      text-align: left;
      padding-bottom: 1.4%;
      .anticon {
        font-size: 1.3rem;
      }
    }
    .PopularQuestions {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .Questions {
        background: #e6ebf2 0% 0% no-repeat padding-box;
        border-radius: 12px;
        height: 4.35rem;
        display: flex;
        align-items: center;
        padding: 0 1.4rem;
        margin-right: 4.75%;
        margin-bottom: 3.55rem;
        opacity: 1;
        cursor: pointer;
        p {
          color: #232323;
          font-size: 1.4rem;
          width: fit-content;
          letter-spacing: 0.22px;
          margin-bottom: 0;
          opacity: 0.6;
        }
      }
    }
    .ant-list {
      padding-top: 1%;
      .ant-list-item {
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 33px #a2a2a229;
        border: 0.30000001192092896px solid #e8e8e8b8;
        border-radius: 12px;
        padding: 2rem 2.8rem 1.1rem;
        opacity: 1;
        .CategoryTitle {
          display: flex;
          align-items: center;
          padding-bottom: 1.8rem;
          img {
            width: 4.4rem;
            padding: 0;
          }
          h3 {
            font-size: 1.6rem;
            font-weight: 600;
            color: #363d58;
            padding-left: 10%;
            margin-bottom: 0;
            opacity: 0.68;
          }
        }
        p {
          color: #4fb8b6;
          text-align: left;
          font-size: 1.5rem;
          cursor: pointer;
        }
      }
    }
  }
}
// HelpSupportPage ends here

// RaiseTicket starts here
.RaiseTickets-Container {
  text-align: center;
  padding: 3% 8% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    color: #363d58;
    font-size: 2.26rem;
    position: relative;
    padding-bottom: 2%;
  }
  .CategoryQuestions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .Questions {
      background: #e6ebf2 0% 0% no-repeat padding-box;
      border-radius: 12px;
      height: 4.35rem;
      display: flex;
      align-items: center;
      padding: 0 1.4rem;
      margin-right: 4.5%;
      margin-bottom: 2.6rem;
      opacity: 1;
      cursor: pointer;
      p {
        color: #232323;
        font-size: 1.4rem;
        width: fit-content;
        letter-spacing: 0.22px;
        margin-bottom: 0;
        opacity: 0.6;
        padding: 0;
      }
    }
  }
  .UploadBody {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .UploadContent {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .UploadDetails {
        display: flex;
        align-items: center;
        p {
          color: #8c9094;
          font-size: 1.5rem;
          text-align: left;
          padding: 1% 0;
          width: 80%;
          width: 22rem;
        }
        .ant-upload.ant-upload-select-picture-card {
          width: 3.8rem;
          height: 3.8rem;
        }
        .ant-upload-list-picture-card .ant-upload-list-item,
        .ant-upload-list-picture-card-container {
          display: none;
        }
      }
      .UploadIMGs {
        display: flex;
        align-items: center;
        margin-top: -1.1rem;
        .IMGs {
          position: relative;
          width: 4.2rem;
          height: 4.2rem;
          margin-right: 1rem;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          padding: 0.4rem;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .anticon-delete {
            display: none;
          }
          .anticon-file {
            font-size: 2rem;
            padding-top: 0.5rem;
            cursor: pointer;

          }
          &:hover {
            img {
              background-color: #00000066;
            }
            .anticon-file {
              background-color: #00000066;
             }
            .anticon-delete {
              display: unset;
              color: $white;
              position: absolute;
              font-size: 1.6rem;
              right: 1.15rem;
              top: 1.15rem;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .Rich-Texteditor {
    width: 80%;
    min-height: 16rem;
    background: $white 0% 0% no-repeat padding-box;
    border: 0.30000001192092896px solid #838383;
    border-radius: 12px;
    text-align: left;
    padding: 0.5rem 0 0.5rem 0.8rem;
    opacity: 1;
    .EditorToolbar__root___3_Aqz {
      display: none;
    }
    .RichTextEditor__editor___1QqIU {
      font-size: 1.45rem;
      font-family: "Poppins", sans-serif;
      .public-DraftEditor-content,
      .public-DraftEditorPlaceholder-root {
        padding: 0 10px;
      }
    }
    .DraftEditor-editorContainer {
      border: none;
    }
  }
  .BtnSetup {
    padding: 5% 0 2.5%;
    button.ant-btn {
      font-size: 1.6rem;
      font-weight: 500;
      width: 16rem;
      height: 4.5rem;
      border: 2px solid #4fb8b6;
      border-radius: 60px;
      margin: 0 0.8rem;
      opacity: 1;
      &:hover,
      &:focus {
        border-color: #4fb8b6;
      }
    }
    button.ant-btn:nth-child(1) {
      background-color: #4fb8b6;
      &:hover,
      &:focus {
        background-color: #4fb8b6;
      }
    }
    button.ant-btn:nth-child(2) {
      background-color: #f3f8ff;
      color: #4fb8b6;
      &:hover,
      &:focus {
        color: #4fb8b6;
      }
    }
  }
}
// RaiseTicket ends here

.BackRoute {
  display: flex;
  align-items: center;
  margin-top: 3%;
  padding-bottom: 2.5%;
  padding-left: 1.35%;
  cursor: pointer;
  width: fit-content;
  h1 {
    color: #363d58;
    font-size: 2.1rem;
    margin-bottom: 0;
    opacity: 1;
  }
  .anticon {
    color: #363d58;
    font-size: 1.75rem;
  }
  .anticon-minus {
    margin-left: -1rem;
    margin-right: 1rem;
  }
}
// TicketsList starts here
.TicketsList-Container {
  padding: 0 3% 0 2.15%;
  .ant-list-split .ant-list-item {
    border-bottom: none;
    padding: 0;
    margin-bottom: 2.4rem;
  }
  .ant-list-something-after-last-item
    .ant-spin-container
    > .ant-list-items
    > .ant-list-item:last-child {
    border-bottom: none;
    margin-bottom: 1rem;
  }
  .ant-list {
    .ant-list-pagination {
      margin-top: 3.4rem;
      text-align: center;
    }
    .ant-pagination-item-active {
      background: #4fb8b6;
      border-color: #4fb8b6;
      border-radius: 50%;
    }
    .ant-pagination-item a {
      color: #222222;
      padding-top: 1px;
      padding-bottom: 1px;
      &:hover {
        color: #4fb8b6;
      }
    }
    .ant-pagination-item-active a {
      color: $white;
      &:hover {
        color: $white;
      }
    }
    .ant-pagination-prev {
      margin-right: 1rem !important;
    }
    .ant-pagination-next {
      margin-left: 1rem !important;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      background: $white !important;
      border-radius: 50%;
      box-shadow: 0 0.6px 2px 0.25px;
    }
    .ant-pagination-prev:focus .ant-pagination-item-link,
    .ant-pagination-next:focus .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
      color: #000000a6;
    }
    .ant-pagination-item:not(.ant-pagination-item-active) {
      border-radius: 50%;
    }
    .anticon {
      vertical-align: -1px;
      color: #bfbfbf;
    }
  }
  .TicketsList-Body {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.6rem;
    background: #f3f8ff 0% 0% no-repeat padding-box;
    border-radius: 15px;
    padding: 2.5rem 4% 2.5rem 2.5%;
    margin-right: 1rem;
    opacity: 1;
    cursor: pointer;
    h3 {
      color: #232323;
      font-size: 1.55rem;
      letter-spacing: 0.22px;
      opacity: 0.79;
      margin-bottom: 0;
    }
    h3:nth-child(1) {
      padding-bottom: 1.2rem;
    }
  }
}
// TicketsList ends here

// MyTicketDetails starts here
.MyTicketDetails-Container {
  padding: 0 3% 0 2.15%;
  .BackRoute {
    padding-bottom: 2%;
    margin-top: 2.25%;
    h1 {
      font-size: 2rem;
    }
    .anticon {
      font-size: 1.7rem;
    }
  }

  .MyTicketList-Container {
    overflow-y: auto;
    height: 87.8vh;
    ::-webkit-scrollbar {
      width: 0.4rem;
      background-color: #e8e8e8cc;
      border-radius: 4px;
      display: inline !important;
    }
    ::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 4px;
      display: inline !important;
    }
    .MyTicketList-Body {
      position: relative;
      background: #f3f8ff 0% 0% no-repeat padding-box;
      border: 2px solid transparent;
      border-radius: 15px;
      padding: 1.5rem 3% 0.6rem;
      margin-bottom: 1.1rem;
      margin-right: 0.4rem;
      opacity: 1;
      cursor: pointer;
      .TicketInfo {
        display: flex;
        //   align-items: center;
        justify-content: space-between;
        h3,
        h4,
        h5,
        h6 {
          color: #232323;
          margin-bottom: 0;
        }
        h5 {
          font-size: 1.325rem;
          letter-spacing: 0.18px;
          opacity: 0.79;
        }
        h5:nth-child(1) {
          padding-bottom: 0.65rem;
        }
        h6 {
          font-size: 1.2rem;
          letter-spacing: 0.16px;
          opacity: 0.45;
        }
        h4 {
          font-size: 1.475rem;
          padding-bottom: 1.2rem;
          letter-spacing: 0.2px;
          opacity: 0.5;
        }
        h3 {
          font-size: 1.55rem;
          letter-spacing: 0.22px;
          .StatusDot {
            width: 1rem;
            height: 1rem;
            display: inline-block;
            border-radius: 50%;
            margin-right: 1rem;
            &::before {
              content: "";
              display: block;
            }
          }
        }
      }
      .Info2 {
        padding-top: 1.55rem;
      }
    }
  }
  .MyTicket-Content {
    background: #f8fbff 0% 0% no-repeat padding-box;
    border-radius: 15px;
    padding: 1.75% 2% 0 2.75%;
    margin-left: 1.75%;
    height: 88vh;
    opacity: 1;
    
    h3 {
      color: #232323;
      font-size: 1.575rem;
      font-weight: 600;
      margin-bottom: 0;
      letter-spacing: 0.22px;
      opacity: 0.79;
    }
    .MyTicketIssue {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        color: #232323;
        font-size: 1.35rem;
        font-weight: 500;
        margin-bottom: 0;
        padding-bottom: 0.7rem;
        letter-spacing: 0.18px;
        opacity: 0.79;
      }
      h3.Status {
        color: $primary;
        text-transform: uppercase;
        text-align: right;
      }
    }
    ::-webkit-scrollbar {
      width: 0.4rem;
      background-color: #e8e8e8cc;
      border-radius: 4px;
      display: inline !important;
    }
    ::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 4px;
      display: inline !important;
    }
    .Message-Container {
      overflow-y: auto;
      height: 68vh;
      .MessageDetails {
        padding-top: 2%;
        .UserInfo {
          display: flex;
          align-items: center;
          padding-left: 0.5rem;
          padding-bottom: 3%;
          .ant-avatar {
            width: 4.6rem;
            height: 4.6rem;
          }
          .NoProfileImg {
            background-color: #4fb8b6;
            font-size: 2.2rem;
            display: flex;
            align-items: center;
          }
          h3 {
            padding-left: 2.4rem;
          }
        }
        p {
          color: #414141;
          font-size: 1.375rem;
          font-weight: 500;
          margin-bottom: 0;
          opacity: 0.75;
        }
      }
      .ant-divider-horizontal {
        margin: 24px 0 0;
      }
    }
    .BtnSetup {
      text-align: center;
      padding: 3.25% 0 5%;
      button.ant-btn {
        font-size: 1.38rem;
        height: 4.1rem;
        background: #4fb8b6 0% 0% no-repeat padding-box;
        border-radius: 60px;
        border-color: #4fb8b6;
        padding: 0 3.4rem;
        opacity: 1;
        &:focus,
        &:hover {
          border-color: #4fb8b6;
        }
      }
    }
    .MessageBox {
      position: relative;
      background: $white 0% 0% no-repeat padding-box;
      border: 1px solid #4fb8b6;
      border-radius: 30px;
      min-height: 4.6rem;
      display: flex;
      align-items: center;
      margin: 1.5% 0 3%;
      padding-right: 0.4rem;
      opacity: 1;
      ::-webkit-scrollbar {
        width: 0.4rem;
        background-color: #e8e8e8cc;
        border-radius: 4px;
        display: inline !important;
      }
      ::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 4px;
        display: inline !important;
      }
      .Rich-Texteditor {
        width: 100%;
        border: none;
        border-radius: 30px;
        padding: 0.5rem 0 0.5rem 0.8rem;
        .EditorToolbar__root___3_Aqz {
          display: none;
        }
        .RichTextEditor__editor___1QqIU {
          font-size: 1.45rem;
          font-family: "Poppins", sans-serif;
          .public-DraftEditor-content,
          .public-DraftEditorPlaceholder-root {
            padding: 0 10px;
          }
        }
        .DraftEditor-editorContainer {
          border: none;
        }
      }
      img {
        width: 2.4rem;
        margin: 0 2rem 0 1rem;
        cursor: pointer;
      }
    }
    .CloseTicketBTN {
      text-align: center;
      padding-bottom: 3%;
      button.ant-btn {
        font-size: 1.4rem;
        width: 15rem;
        height: 4rem;
        background: #4fb8b6 0% 0% no-repeat padding-box;
        border-radius: 60px;
        border-color: #4fb8b6;
        opacity: 1;
        &:focus,
        &:hover {
          border-color: #4fb8b6;
        }
      }
    }
  }
}
// MyTicketDetails ends here
