@import "../../../../theme/base/_colors.scss";

.DashboardSettings-container {
  .DashboardSettings-body {
    position: relative;
    margin: 1.5% 1.5% 0 2%;
    h3.DashboardSettings-Title {
      color: #4fb8b6;
      font-weight: 600;
      font-size: 2.1rem;
      padding-top: 2.05%;
      padding-bottom: 1.9%;
      cursor: pointer;
    }
    .Security-Container {
      overflow: hidden;
      .Security-Body-content {
        margin-right: 4rem;
        .Security-Body-Edit {
          width: 96%;
          .ant-tabs-nav .ant-tabs-tab-active {
            color: #4fb8b6;
            padding: 1.2rem 0 1.7rem;
            font-size: 1.1em;
          }
          .ant-tabs-ink-bar {
            width: 8rem !important;
            height: 0.3rem;
            background-color: #4fb8b6;
          }
          h2 {
            font-weight: 600;
            margin: 1.4rem 0 2.7rem;
          }
          h3 {
            font-weight: 600;
            margin-bottom: 0;
          }
          .Security-Edit {
            color: #4fb8b6;
            position: relative;
            text-align: right;
            bottom: 2.3rem;
            font-size: 1.1em;
            cursor: pointer;
          }
          .Security-Content {
            color: #000;
            opacity: 0.5;
            margin-top: -1.4rem;
            font-size: 1em;
          }
          .Edit-Input {
            margin-bottom: 2%;
            margin-top: -1.5rem;
            input.ant-input {
              margin-bottom: 1rem;
            }
            button.ant-btn {
              background-color: #4fb8b6;
              color: $white;
              border-color: #4fb8b6;
              border-radius: 8px;
            }
          }
          .Security-divider {
            margin: 2rem 0 2.6rem;
          }
        }
        .Security-Body-Safe {
          margin-top: 4.8rem;
          .ant-card-bordered {
            border: 2px solid #e8e8e8;
            border-radius: 10px;
          }
          .ant-card-body {
            padding: 3.4rem 2.6rem 2.6rem;
          }
          img {
            width: 3rem;
          }
          p {
            font-weight: 600;
            font-size: 1.7rem;
            opacity: 0.6;
            margin-top: 1.2rem;
            margin-bottom: 2rem;
          }
          .Security-Safe-Content {
            font-weight: 600;
            color: #81837f;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

.ant-modal.PasswordModal {
  .ant-modal-content {
    background: $white;
    border-radius: 12px;
  }

  .ant-modal-close {
    top: 7.5px;
    right: 10px;
    .anticon {
      font-size: 2.7rem;
    }
  }

  @media screen and (max-width: 56.25em) {
    .ant-modal-close {
      top: 18px;
      right: 10px;
      .anticon {
        font-size: 4rem;
      }
    }
  }

  @media screen and (max-width: 37em) {
    .ant-modal-close {
      top: 0;
      right: 0;
      .anticon {
        font-size: 2.8rem;
      }
    }
  }
  .ant-modal-body {
    padding-inline: 4.4rem;
    .Error {
      margin-bottom: 0.2rem;
      margin-top: -1.6rem;
      color: #f44336;
      font-size: 1.2rem;
    }
    h2 {
      color: #4fb8b6;
      text-align: center;
      padding-bottom: 3rem;
    }
    .ant-input-affix-wrapper {
      margin-bottom: 1.8rem;
      input.ant-input {
        height: 4.2rem;
        box-shadow: none;
        &:focus,
        &:hover {
          border-color: $primary;
        }
      }
      .anticon {
        font-size: 1.8rem;
      }
    }
    .Btn-Setup {
      text-align: center;
      padding-top: 2rem;
      button.ant-btn {
        width: 12rem;
        height: 4rem;
        border-radius: 8px;
        font-size: 1.4rem;
        font-weight: 500;
        border-color: #4fb8b6;
        &:hover,
        &:focus {
          border-color: #4fb8b6;
        }
      }
      .UpdateBtn {
        background: #4fb8b6;
        margin-right: 1rem;
      }
      .CancelBtn {
        color: #4fb8b6;
      }
    }
  }
}

@media only screen and (min-width: 56.25em) {
  .Security-Container {
    .anticon.anticon-left {
      display: none;
    }
  }
}

@media only screen and (max-width: 56.25em) and (min-width: 600px) {
  .Security-Container {
    overflow: hidden;
    .Security-body-content {
      margin: 1rem;
      .ant-row {
        position: relative;
        height: auto;
        margin-right: 0;
        margin-left: 0;
        zoom: 1;
        display: flex;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        flex-direction: column-reverse;
      }
      p:nth-child(1) {
        display: none;
      }
      .anticon.anticon-left {
        padding-left: 2%;
        font-size: 4rem;
        margin-top: 1.4rem;
        position: absolute;
        top: unset;
        left: 12%;
        color: $dark;
        font-weight: 600;
      }
      h1 {
        color: #4fb8b6;
        position: unset;
        top: 17%;
        left: 26%;
        font-weight: 500;
        font-size: 4.3rem;
        text-align: center;
        z-index: 1000;
        opacity: 0.8;
      }
      .Security-Body-Edit {
        width: 75%;
        margin-left: 10%;
        .ant-tabs-nav .ant-tabs-tab-active {
          color: #4fb8b6;
          padding: 1.2rem 0 1.7rem;
          font-size: 1.1em;
        }
        .ant-tabs-ink-bar {
          width: 8rem !important;
          height: 0.3rem;
          background-color: #4fb8b6;
        }
        .ant-tabs-bar {
          margin: 0 0 16px 0;
          border-bottom: 1px solid #e8e8e8;
          outline: none;
          -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          display: none;
        }
        h2 {
          font-weight: 600;
          font-size: 4rem;
          color: $primary;

          margin: 1.4rem 0 2.7rem;
        }
        h3 {
          font-weight: 500;
          margin-bottom: 0;
          color: $black;
          font-size: 3rem;
        }
        .Security-Edit {
          color: #4fb8b6;
          position: relative;
          text-align: right;
          bottom: 2.3rem;
          font-size: 3rem;
          line-height: 15px;
        }
        .Security-Content {
          color: $dark;
          opacity: 0.8;
          margin-top: -1.4rem;
          font-size: 2rem;
          margin-bottom: 5rem;
        }
        .Security-divider {
          margin: 2rem 0 2.6rem;
        }
      }
      .Security-Body-Safe {
        margin-top: 4.8rem;
        .ant-card-bordered {
          width: 90%;
          background: $white 0% 0% no-repeat padding-box;
          box-shadow: 0px 10px 40px #71717129;
          border: 1px solid $white;
          border-radius: 15px;
          opacity: 1;
          padding: 2% 2%;
          margin-left: 6%;
          margin-bottom: 8%;
          position: relative;
          left: 18%;
        }
        .ant-card-body {
          padding-left: 2rem;
          padding-top: 1rem;
          padding-bottom: 2rem;
        }
        img {
          width: 6rem;
        }
        p {
          font-weight: 600;
          font-size: 2.8rem;
          opacity: 1;
          margin-top: 1.2rem;
          margin-bottom: 0.1rem;
          color: #555555;
        }
        .Security-Safe-Content {
          font-weight: 500;
          color: $secondary;
          font-size: 1.8rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 37.5em) {
  .Security-Container {
    overflow: hidden;
    .Security-body-content {
      margin: 1rem;
      .ant-row {
        position: relative;
        height: auto;
        margin-right: 0;
        margin-left: 0;
        zoom: 1;
        display: flex;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        flex-direction: column-reverse;
      }
      p:nth-child(1) {
        display: none;
      }
      .anticon.anticon-left {
        padding-left: 2%;
        font-size: 2.1rem;
        margin-top: -3%;
        position: absolute;
        top: 19.5%;
        left: 12%;
        color: $dark;
        font-weight: 600;
      }
      h1 {
        color: #4fb8b6;
        position: absolute;
        top: 17%;
        left: 26%;
        font-weight: 500;
        font-size: 2.6rem;
        text-align: center;
        z-index: 1000;
        opacity: 0.8;
      }
      .Security-Body-Edit {
        width: 88%;
        margin-left: 5%;
        .ant-tabs-nav .ant-tabs-tab-active {
          color: #4fb8b6;
          padding: 1.2rem 0 1.7rem;
          font-size: 1.1em;
        }
        .ant-tabs-ink-bar {
          width: 8rem !important;
          height: 0.3rem;
          background-color: #4fb8b6;
        }
        .ant-tabs-bar {
          margin: 0 0 16px 0;
          border-bottom: 1px solid #e8e8e8;
          outline: none;
          -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          display: none;
        }
        h2 {
          font-weight: 600;
          font-size: 2.2rem;
          color: $primary;

          margin: 1.4rem 0 2.7rem;
        }
        h3 {
          font-weight: 500;
          margin-bottom: 0;
          color: $black;
        }
        .Security-Edit {
          color: $primary;
          position: relative;
          text-align: right;
          bottom: 2.3rem;
          font-size: 1.1em;
          line-height: 15px;
        }
        .Security-Content {
          color: $dark;
          opacity: 0.8;
          margin-top: -1.4rem;
          font-size: 1.3rem;
          margin-bottom: 5rem;
        }
        .Security-divider {
          margin: 2rem 0 2.6rem;
        }
      }
      .Security-Body-Safe {
        margin-top: 4.8rem;
        .ant-card-bordered {
          width: 90%;
          background: $white 0% 0% no-repeat padding-box;
          box-shadow: 0px 10px 40px #71717129;
          border: 1px solid $white;
          border-radius: 15px;
          opacity: 1;
          padding: 2% 2%;
          margin-left: 6%;
          margin-bottom: 8%;
        }
        .ant-card-body {
          padding-left: 2rem;
          padding-top: 1rem;
          padding-bottom: 2rem;
        }
        img {
          width: 3rem;
        }
        p {
          font-weight: 600;
          font-size: 1.7rem;
          opacity: 1;
          margin-top: 1.2rem;
          margin-bottom: 0.1rem;
          color: #555555;
        }
        .Security-Safe-Content {
          font-weight: 600;
          color: $secondary;
          font-size: 1.2rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 37.5em) and (min-height: 700px) {
  .Security-Container .Security-body-content h1 {
    color: #4fb8b6;
    position: absolute;
    top: 15%;
    left: 26%;
    font-weight: 500;
    font-size: 2.6rem;
    text-align: center;
    z-index: 1000;
    opacity: 0.8;
  }
  .Security-Container .Security-body-content .anticon.anticon-left {
    padding-left: 2%;
    font-size: 2.1rem;
    margin-top: -3%;
    position: absolute;
    top: 18%;
    left: 12%;
    color: $dark;
    font-weight: 600;
  }
}
@media only screen and (max-width: 37.5em) and (min-height: 800px) {
  .Security-Container .Security-body-content h1 {
    color: #4fb8b6;
    position: absolute;
    top: 13.5%;
    left: 26%;
    font-weight: 500;
    font-size: 2.6rem;
    text-align: center;
    z-index: 1000;
    opacity: 0.8;
  }
  .Security-Container .Security-body-content .anticon.anticon-left {
    padding-left: 2%;
    font-size: 2.1rem;
    margin-top: -3%;
    position: absolute;
    top: 16%;
    left: 12%;
    color: $dark;
    font-weight: 600;
  }
}
